import React, { useEffect, useState } from "react";
import "./MapInteractive.scss";
import officeData from "../../offises.json";

const MapInteractive = ({ setRegion, setOfficesAddr, path, setPath }) => {
    //весь этот блок предназначен для приведения json к адекватному виду
    const officesData = officeData["Города"]["Город"];
    const officesPropVal = officesData.map((off) => off.ЗначенияСвойства);
    const [officesState, setOfficesState] = useState([]); //таблица с удаленными пустыми адресами
    const [officesAllState, setOfficesAllState] = useState([]); //таблица с пустыми адресами
    const [officesEmptyState, setOfficesEmptyState] = useState([]); //таблица с пустыми адресами

    useEffect(() => {
        officesPropVal.forEach((off) => {
            if (off[1].Значение.length > 0) {
                const obj = { region: off[7].Значение, addr: off[1].Значение };
                setOfficesState((prev) => [...prev, obj]);
            }
        });
        officesPropVal.forEach((off) => {
            if (off[1].Значение.length == 0) {
                const obj = { region: off[7].Значение, addr: off[1].Значение };
                setOfficesEmptyState((prev) => [...prev, obj]);
            }
        });
        officesPropVal.forEach((off) => {
            const obj = { region: off[7].Значение, addr: off[1].Значение };
            setOfficesAllState((prev) => [...prev, obj]);
        });
    }, []);
    //

    // console.log(officesAllState)
    // console.log(officesEmptyState)

    useEffect(() => {
        // const regions = document.querySelectorAll("[data-title]");
        // regions.forEach((region) => {
        //     for (var i = 0; i < officesAllState.length; i++) {
        //         if (
        //             region.dataset.title.includes(officesAllState[i].region) &&
        //             officesAllState[i].addr.length == 0
        //         ) {
        //           console.log(region.dataset.title)
        //         }
        //     }
        // });
    });

    useEffect(() => {
        const regions = document.querySelectorAll("[data-title]");

        // const regionsDisabled = document.querySelectorAll(".rf-empty");
        // console.log(regionsDisabled);
        // regionsDisabled.forEach(reg=>{
        //     reg.classList.add("rf-disabled")
        // })

        //выбор элементов содержащих data-title
        const hover = (region) => {
            region.onmousemove = (e) => {
                if (region.classList.value.includes("rf-empty")) {
                    e.preventDefault();
                } else {
                    setRegion(region.dataset.title); //динамическое изменение региона
                }
            };

            region.onclick = (e) => {
                if (region.classList.value.includes("rf-empty")) {
                    e.preventDefault();
                } else {
                    console.log(region.dataset.title);
                    setOfficesAddr([]); //очистка адресов прошлого региона
                    setPath(region); // фиксация выбранного path из карты
                    for (var i = 0; i < officesState.length; i++) {
                        if (
                            region.dataset.title.includes(
                                officesState[i].region
                            )
                        ) {
                            const addr = officesState[i].addr;
                            setOfficesAddr((prev) => [...prev, addr]);
                        }
                    }
                    regions.forEach((region) => {
                        region.classList.remove("rf-clickMarked"); //снятие зеленой окраски
                    });
                    region.classList.add("rf-clickMarked");
                    //установка зеленой окраски для выбранного
                }
            };
        };

        regions.forEach((region) => hover(region));
    });

    return (
        <div className="map__mapImg">
            <div className="rf-map">
                <div className="district">
                    <b></b>
                    <span></span>
                </div>

                <svg
                    x="0px"
                    y="0px"
                    viewBox="0 0 808.9 458.9"
                    enableBackground="new 0 0 808.9 458.9"
                >
                    <path
                        data-title="Республика Крым"
                        className="rf-empty"
                        data-code="RU-CR"
                        d="M1.6,328.6H4l1.2,0.8l1.5,0.2v-0.9l1.3-0.2l0.8,1.3h3.3l0.1-1.1
        l1.2,0.3v2.1l1.4,1.3l-0.8,2l1.6,1.6v2.9l0.4,1.1l-0.5,1.3l-0.8,1.1l1.2,1.2l0.5,1.2l2,0.6l2.1,0.3l2.5,4.2l-1.5,0.2l-1.3,1.3
        l-2.6-0.9l-2-1.8l-0.4-1.1l-0.2-1l-1.3-0.9l-2.6,0.6l-1.7-0.9l-2.1-1.2l-3,0.5l-2.2-0.4l-1-1.7l-0.7-2.6l1.6-0.1l1.9-2.3l0.5-2.4
        L4.2,334v-1.7l-1-1.6l-1.6-1.2L1.6,328.6z"
                    />
                    <path
                        data-title="Мурманская область"
                        data-code="RU-MUR"
                        d="M160,158.1l6.1,5.5l-0.3,2.3l-0.7,1.9l1.4,1.4l2.1-0.5l1.3,0.8
        l1.6-0.1l0,0l-0.5-2.8l1.2-1.2l0.8-2.2l1.8,1.8l-1.5,1.5l-0.6,2.9l1.8,0.8l0.9,4.7l1.6,2.8l0.9,2.5l1.1,2.7l2.4,2.4v3.4l2.3,2.3
        l3.7,2.1l3.4,1.3l3.9-0.8h3.5l3.4-3.4l2.2-3.9l0.5-4.6l0.1-2.8l-2.2-2.2l-0.1-3.9l-0.8-1.8l0.6-3.2v-3.7l-1-3.6l-2.1-2.1v-2.9
        l-1.8-0.9v-2.7l-1.6-1.6l-2.2-1.1v-2.4l-0.6-2.4l1.3-1.3l1.5,1.5l1.4,0.9l-0.6-2.3v-2l-3-0.1l-2.2,0.3l0.5-2.7l-2.2,1.5l-1.8-1.8
        h-2.2l-3.7-1.4l-5.3,2.7l-4.1,2v6.8l-2,3.5h-6.5l-3.9,3.9L160,158.1L160,158.1"
                    />
                    <path
                        data-title="Псковская область"
                        className="rf-empty"
                        data-code="RU-PSK"
                        d="M75.6,243l1.7,2.1l0.8-2.2h1.8l0.8-1.3l0.7-1.5l0.3-2.7l1.1-1.1
        l0.2-1.6l2-1.6l-1.1-1.1l1-1l1.1-0.6v-1.8l1.1-0.6l1.3-1.3l1.7-0.8l0.6-1.8l-1.3-1l-0.4-1l0.9-0.9v-1.6l1-1l2.1-0.2l-0.5-2.5
        l0.7-2.3l-0.8-1.8l-0.1-1.8l-1.6-1.6l0.8-1.7l-1.5-1.8l-2.9-0.1l-2.2,2.9l-2.7,1l-0.1,1.9l-1.4,1.6l-3.2,0.1L76,214v2.7l-3,1.9
        l-2,0.5l0.6,2.3l-1.4,2.5l-2.4,2.4l-0.4,1.8l1.1,2.5l1.8,1.8l-0.6,2l-0.8,1.9l2.4,0.6l2.2,2v4.3L75.6,243"
                    />
                    <path
                        data-title="Смоленская область"
                        data-code="RU-SMO"
                        d="M71.1,245.3l-0.9,1.9l-2.5,0.8l-1,2.8l-1.4,3.3l-0.8,3l0.1,2.2
        l-1.3,0.9l1.9,1.9l-0.8,2.8l-0.6,2.5l1.9,1v2.2l1.2-1.2l1.3-2.1l1.6,0.1l1.9-1l1.7-0.4l1.8-1.8H77l1.3,1.3l0.4,1.5l1.8,0.2l0.6-0.6
        l1,0.3l1.8,1.4l1.4-1.1h1.8l2.3-0.5l0.9-1.4l1.7-1.7l2.3-0.9l0.6-2.1l-0.6-1.3l-1.5-1.2l-1-1.9l-1-1v-1.3l-2.2-0.6l-2,0.6l-2.5-0.6
        l-1.2-1.2l-1.9-1.1l-1.4-1.4l-0.5-2.6l-1.7-1l-0.2-0.8l-1.7-2.1l-2.2,0.2L71.1,245.3"
                    />
                    <path
                        data-title="Брянская область"
                        data-code="RU-BRY"
                        d="M61.4,268.1l-2.5-0.9l-0.8-1.9l-1.4-1.5l-1.9,0.1l-0.6,2.3
        l-1.6,1.9l-2.4,2.4l-1.1,2l1.1,1.9l1.3,1.3l1.7-1.5l1.1,1.6l1.4,1.5l1.9,0.3l1.4,1.4l1,2.5l-0.3,2l-0.9,3.4l1.4,0.1l0.9-1.3l1.8-0.6
        l1.1,0.8h1.6l1.4-1.4l1.3,0l0.3-1.1l-0.4-1.1l1.2-0.4h1.4l1,1l0.7-0.7l0.4-1.6l0.8-0.8l-0.3-1.8l-1.2-1.2v-1.5l1-1v-1.9l0.5-1.9
        l-1-1l0.1-1.2l-1.2-1.9l-1.9,1l-1.6-0.1l-1.3,2.1l-1.2,1.2v-2.2l-1.9-1L61.4,268.1"
                    />
                    <path
                        data-title="Курская область"
                        data-code="RU-KRS"
                        d="M57,298.3v-2.7l-1.4-2.5l-1.4-2.2l1.3-1l0.8-1.9h1.5l1.1-1
        l1.4,0.1l0.9-1.3l1.8-0.6l1.1,0.8h1.6l0.7,1.1l1.3,0.5l1,0.8v2.1l1.5,0.4l0.4,1.2l1.3,0.6l0.4,2.8l1.4,2.3l0.4,2.9l1.4,0.3l1,1v2.2
        l1.6,0.3l1.8,1.1l-1.5,1.5l-1.9-0.7l-1.2,1.2v1.9l-1.3,0.6l-0.6-1.9l-1.9-1.9l-2.9-1.8l-1.8,0.4l-3.5-0.7v-1.4l-1.8-1.9l-0.9,0.9
        h-2.2l-2.6-0.6L57,298.3"
                    />
                    <path
                        data-title="Белгородская область"
                        data-code="RU-BEL"
                        d="M67.4,326.1l-0.8-2.9l-1.9-1.1l-2-2.3l0.8-2.7l-0.8-3.9l-2.4-1.8
        l-2.8-2.2l-0.9-2.9l-1.9-1.5l-0.6-1.8l1.6-1.9l2.6,0.6h2.2l0.9-0.9l1.8,1.9v1.4l3.5,0.7l1.8-0.4l2.9,1.8l1.9,1.9l0.6,1.9l0.5,1.7
        l-1.1,0.9l1.6,1.6l-1,1.9h-1.3v2.5l0.4,1.9l-1.9,1v2.2l-1.5,0.1l0.4,1.6l-2.2,1.2L67.4,326.1"
                    />
                    <path
                        data-title="Воронежская область"
                        data-code="RU-VOR"
                        d="M67.8,326.5l2.2-1.2l-0.4-1.6l1.5-0.1v-2.2l1.9-1l-0.4-1.9v-2.5
        h1.3l1-1.9l-0.9-0.9l-0.7-0.7l1.1-0.9l-0.5-1.7l1.3-0.6v-1.9l1.2-1.2l1.9,0.7l1.5-1.5l2.2,0.4l1,1.9l2.3,0.8l-0.5,2.3l1.5,1.5
        l1.9,0.2l1.8,2.5l1.3,1.3l-0.6,1.9l1.5,1.5l2.4,1l0.8,1.6l1.5,1.5l0.8,2.5l1,2.6l-0.9,0.9h-2.6l-1.9-1.1l-0.4-1.4l-1.9-0.4l-1.8,1.6
        h-3.6l-0.9,3l-3,3h-3.3l-1.1,1.1h-1.8l-0.9-1.2h-2.2V333l-1-1l-2.2-1v-2.7L67.8,326.5"
                    />
                    <path
                        data-title="Карачаево-Черкесская Республика"
                        className="rf-empty"
                        data-code="RU-KC"
                        d="M39.7,397.4l-1.9-0.9l-2.8-1.9l-0.9-3l-1.9-2.9l-0.6-2.5l1.5-1.5
        l1.8-0.3l1.3-1.3h1.3l0.7,1.5l1.1,1.6h2.5l1.9-2.4l1.5,0.5v2l1.4,1l0.3,1.6l0.7,1h-2.2l-0.6,1.1l1,1l0.7,1.1l-0.7,1.6l-1.8,0.7
        l-1.2-0.1l-1.5,1.5L39.7,397.4"
                    />
                    <path
                        data-title="Республика Северная Осетия — Алания"
                        className="rf-empty"
                        data-code="RU-SE"
                        d="M44.2,406.3l0.5,4.1l1.8,1.8l2.8,0.4l1.6,1.6l0.4,1.3l1.2-2h1.9
        l0.2-1.3l-0.6-2l0.6-3l1.7-0.7l2.6,0.1l-0.5-2.2l-2.4-2.2l-1,0.8v1.5l-0.9,0.9l-2.3,0.1l-0.8,0.8l-1.3-1h-1.6L47,406l-1.1-0.2
        L44.2,406.3"
                    />
                    <path
                        data-title="Республика Ингушетия"
                        className="rf-empty"
                        data-code="RU-IN"
                        d="M58.7,411.5l-1.2,2.6l-1.5,2.4l-1.9,1.4l-0.6-2.3l-2.2-0.3l1.2-2
        h1.9l0.2-1.3l-0.6-2l0.6-3l1.7-0.7l0.9,1.7l2,1.6L58.7,411.5"
                    />
                    <path
                        data-title="Республика Дагестан"
                        className="rf-empty"
                        data-code="RU-DA"
                        d="M77.1,405.7l-1.3,1.3v2.1l1.3,1.3l0.8,2.4l-0.6,2.4l-0.6,2.2
        l-1.5,1.5v0.9L74,421l-2.8,4.6v3.5l0.8,4.3l-0.5,3.3l0.3,4.1l-3,1.4l-2-0.9l-2.9,1l-2.8-0.6l-0.4-2.4l-1.4-4.2l-0.3-3l-2-1.4V429
        l-1-2.7l-1.8-2.3l1.1-1.9l2.2-0.3l1.4-1.1l2.1,0.2l1.3-1l2.1,1.2l0.8-3l2.2-2.7l2.3-1.3l1.3-0.4l-0.2-2.9l-2.6-0.2l-1.4-1.9l-1.8-2
        l0.8-2l-1.3-0.6v-1.1h1.6l0.9-0.8l-0.6-1l1-1l1.6,0.6l1.9-0.6l2-1.3l1.7,1.2l0.9,1.3l1.4,0.5l0.6,1.6L77.1,405.7"
                    />
                    <path
                        data-title="Республика Калмыкия"
                        className="rf-empty"
                        data-code="RU-KL"
                        d="M78.7,406.2l2.7-0.8h2.4l2.2-2.5l1.8-0.8v-1.8l-1.4-1l-1.7,0
        l-0.4-1l4.1-2.9v-1.2l-0.8-0.8v-1.5l-0.9-1.2l1-1l1.4,0.6l0.2,1h0.9l0.2-1.6l2.7,0.2l0.8-2l-1.3-1.3l0.9-3.2l1.5-0.8l0.3-1.5
        l0.3-1.6l-2.8-0.1l-1.2-1.6l-0.2-2.4l0.4-1.9l0.3-2.1l-1.5-1l-0.7-2.5l-2.3-0.5l-1.3-1.3v-1.6l-1.2-0.4l-0.9,2.5l-1.3,0.5l-1.3-1.3
        l-1.5,0.6l-1.4-1.4l-2.3,2.1v2.7l1.6,0.1l1.9-1v1.9l-1.2,2.6l-2.6,1.9h-2.9l-2.7,2.8l-1.5-0.4l-0.6,0.6l-1.4-0.5l-1-1l-0.9-3.8
        l-1.6-0.4l-1.2-1.2v-1.7l-0.7-1.8h-1.5v1.2l-0.9,0.9H57l-1.2-0.9l-1.6,1.3l1.4,0.7l0.6,1.7h1.3l1-0.4L59,374l1,1l2.3-0.4l1.4,0.6
        L64,377l1.2,1.4l0.4,1.6l1.2,1.2l0.8,2.3l-0.9,1.7l1,1.8l1.3,1.3l1,1.3v1.5l1.4,1.4l0.3,1.9l1.1,1.6v2.9l1.7,1.2l0.9,1.3l1.4,0.5
        l0.6,1.6l-0.2,2.2L78.7,406.2"
                    />
                    <path
                        data-title="Оренбургская область"
                        data-code="RU-ORE"
                        d="M202.8,387.3l0.8,1.8l1.8,1.8l1.2,1.2l0.5,2.2l1.8,1.8l-1.8,2.4
        l-1.6,1.6h-2.8l-2.9,0.2l-1.5-1.5l-1-2.1h-1.3l-1.4,1.4h-2.2l-2.5-2.5l-0.5-1.4l-1.9-1.9v-3.2l-1.6-1.6h-2.9l-2.4,0.9l-0.9-1.9
        l-2.4-1.9l-2.2-1.1l-2.9,1.4l-2.7,0.4l-2.2-0.6l-1.5-2.9l-2.2-1.9l-1.5,2.5h-1.6v-2l1.5-1.5l-1-2.2l-1.3-3.3l-0.5-3.4l-1.9-2.3
        l-3-1.1l-0.9-2.2l-1.6-1.6l-3,0.5h-1.8v-2.4l-0.9-2.5l0.1-1.4h4.5l1.3-2.6l2.4-1.7l1.4,0.4l0.8-1.7l3.5-0.6l1.7-4l2.9-1.6l2.2-2.2
        l0.8-3.5h2.1l2.1,2.1l-1.5,1.5l1.3,1.2l0.8,1.4l-0.1,3.3l1,1l-0.7,3.5l1.4,0.9l1.7,1.7l1.1,2.8v2.8l0.8,1.7l-0.7,1.7v2.4l-0.5,1.2
        l1.5,0.8l1.3-1.3h2.2l0.8,1.4l-0.8,1.9l-2.1,0.8l0.5,1l1.3,0.3l-0.4,2.5l-0.8,0.8l0.4,2h1.2l0.3,1.1l1.5,1.5l1.5-0.7l1.1-1.1
        l1.3,0.7l0.5,1.3l1.4,1l1.6,0.4l1.6-1.2l1.1-2.5h2.1h2.4l1.5-0.6l2.1,0.9h1.3l0.9,1.6l-0.6,1.6l-2.4,1.2l0.2,1.6h1.3L202.8,387.3"
                    />
                    <path
                        data-title="Челябинская область"
                        data-code="RU-CHE"
                        d="M229.8,371.6l-3.2-0.6l-2.3,0.6l-2-1.8l-2.9-1.5h-2.7l-0.9,2.8
        l0.9,1.9l-1.8,1.8l1.4,0.9l1.4,1.4l1.7,1.7l-1.6,1.6h-1.8l-2.2-2.2l-3.3,0.1l-2.2,0.9l0.9,3.2l-0.9,2.3l-2.2,0.6l-2.5-0.6l-1,2.7
        l-0.4-2h-1.3l-0.2-1.6l2.4-1.2l0.6-1.6l-0.9-1.6h-1.3l-2.1-0.9l-1.5,0.6h-4.5l0.8-1.3l2-0.9l0.3-1.9l1.7-3.1h1.3v-2.2l1.7-2.2v-1.4
        l2.6-2.6l2-0.7v-0.9l2.1-0.2l0.9,1.2h1.5v-2.4l-0.9-2.8l-3.1-1l-1.3,0.9l-3.1-0.3l-1.6-2.6l-0.4-3.4l-1.2-1.2l-0.5-2.4l2.8-2.1
        l1.9,0.6l2.1,1l1.4,1.4v1.7l2.6,1l1.7-1.7l2.9-0.7v-1.5l1.5-2.8l0.6-1.9l-0.5-2.1l1.7-0.7l2.1,1.4l0.7,1.3l2.3,0.8l0.4,1l1.7,0.2
        l1.7,1.7l1.6-1.6l1.8,1l1.4,1.4l1.3,1.3l0.1,2.8l0.4,3.2l-1.2,1.9h-2.2l-1.3,1.3l0.7,2.4l-1.2,1.2h-1.7l-0.5,1.7l1.5,1.5l1.6,0.1
        l0.3,1.2h2.5l1,2.5l-1.9,1.9L229.8,371.6"
                    />
                    <path
                        data-title="Омская область"
                        data-code="RU-OMS"
                        d="M305.6,394.7l-2.1,2.1l-3.5,1l-3,1.6l-1.4-2l-2.7,1.3h-2.2
        l0.8-3.4l3-1.4v-1.8H292l-2.3-1.4l-1.4,1.4l-2.5-0.8l0.8-3l-1.8-1.8l-2.5,1.5l-2.8-0.8l-0.7-2.2l1.6-1.6v-2.4l0.6-1.8l-1-3.2
        l-1.9-0.9v-1.5l0.7-1.5l1.3-1.3v-1.7l1-0.2l1.3-1.3l0.5-1.3v-2.2l3.2-3.2l2.4-0.4l0.9-0.9V358l-1.9-1l-0.6-1.5l-1.2-1.2l-1.7-0.9
        V352l2-1l-0.9-2.6l0.2-2l1.8-2l0.9-1.5l1.9-1.3l2,2l-1.2,1.2v1.5l1.7,0.6l2.2,0.7l1.4,0.7l1.6,1h2.6l0.9-1.2l2.2,1.1l1.9,0.6h3
        l2.5-1.2l2-2l1.1-1.6l0.4,1.2l-0.7,1.6l-0.8,1.7v2.6l1.5,1.5l-0.4,3.2l1.4,2.9l0.8,2.2v3.2v2.9l-2,2l0.4,1.4v1.9l-1,1l-1.8-0.7
        l-1.6,0.7l-2.4,2.8l-1.4,3.5l0.6,4.5v2.6l1.3,1.3v1.5l-1.4,1.4L305.6,394.7"
                    />
                    <path
                        data-title="Новосибирская область"
                        data-code="RU-NVS"
                        d="M318.4,405.7l-1.4-1.4l-1.5-3.1l-1.6-1.6l-2.2-0.9v-2.3h1.8l1.5-2
        l-0.6-1.8h-2.3l-1.9,1.9l-2.7,0.1l-2,0.1l1.1-3l1.4-1.4v-1.5l-1.3-1.3v-2.6l-0.6-4.5l1.4-3.5l2.4-2.8l1.6-0.7l1.8,0.7l1-1v-1.9
        l-0.4-1.4l2-2v-6.1l2.8,0.7l1.5,1.5h3.1l3.4,0.5l2.9,1.5l3.4,1.4l2.1,2.1l1.7,1.7l2.5,3.5h5.5l2.2,3h2.6l3-0.8l1.8-1.8l1.6,0.8
        l0.5,3.2v2.4l1,1l-1.2,1.2l1.4,1.4l1.6-1.6l1.7-1.9l3.2,0.2l0.4,4.2l0.9,1.8l0.7,3.8l-0.5,5.4l1.6,2.4l-4.2,2.4h-2.5l-1.7,1.3
        l-1.7-1l-1.7,1.7l-1.5-1.5l-2.4,2.4l-1.1,2.9l-1.7,0.8l-1.7-2.5h-1.4l-1.1-1.7v-1.8l-2.3-2.3l-1.2-1.2l-2.6,0.1l-3.7,1.2l-3.7,2.8
        H327l-2.5,1l-1.9,0.7l-1.4-1.4l-0.9,1.2L318.4,405.7"
                    />
                    <path
                        data-title="Республика Алтай"
                        className="rf-empty"
                        data-code="RU-AL"
                        d="M396.3,452.3l-3,0.7l-2.1,2.1l-3.8-0.1l-3.2,1.8l-1.6,1.6
        l-3.7-0.6l-1.1-1.9l-1.4-2.5l-0.7-2.6l-2,2l-3.3,1.3l-3-1L364,452v-3l-2.7-1.6l-1-2.3l-2.2-1.4l-0.5-3l2.5-0.2v-2.2l-1.1-1.1
        l-1.7-1.7l1.2-1.2l2.7-0.9l3.3-1.2h2.2l3-3.1V427l1.5-1.5l2.4,1.5l1.2-1.6h1.3l1-1.5l-1.4-1.4l1.1-2l2-1l2.5,2.2h2.5l2.2,1.1h1.2
        l0.1,2.3l-1.6,1.6l-1.9,1.9l0.6,2.5l-1.2,1.2l2.6,2.6h2l1.2-2.5h1.3l0.4,2.9l0.9,2.8l2.9,2.9l2.3,2.2l-0.6,1.8l-2.1,0.5l-1,1
        l1.9,1.9v2.1L396.3,452.3"
                    />
                    <path
                        data-title="Алтайский край"
                        data-code="RU-ALT"
                        d="M357.6,440.6l-1.8-1.6l-2.4-1l-1.6-1.6h-3.2l-1.6,1.6l-3.2,0.5
        l-1.3-1.3l-2.8,0.5l-1.5-1.5l-1.4-1.4v-1.9l-1.4-1.6l-2,0.6l-1.4,2.9l-2.8,0.5l-1.6-3.3l-0.9-2.9l-0.6-3.3l-2.2-3.8v-3l-2-1.9v-4.2
        l-1.5-5.1l-2.2-2.2l1.5-0.7l1.3-1.5l1.4,1.4l4.4-1.7h4.2l3.7-2.8l3.7-1.2l2.6-0.1l3.5,3.5v1.8l1.1,1.7h1.4l1.7,2.5l1.7-0.8l1.1-2.9
        l2.4-2.4l1.5,1.5l1.7-1.7l1.7,1l1.7-1.3h2.5l4.2-2.4l1.5,2.9h2.3l1.6,3.1l1.4,1.4l0.5,2.4l2,1.2h1.9l0.1,1.7l-1.9,0.9l1.3,2.4v2
        l0.8,2.1l-2,1l-1.1,2l1.4,1.4l-1,1.5h-1.3l-1.2,1.6l-2.4-1.5l-1.5,1.5v1.9l-1.4,1.4l-1.6,1.7h-2.2l-3.3,1.2l-2.7,0.9l-1.2,1.2
        l1.7,1.7l1.1,1.1v2.2L357.6,440.6"
                    />
                    <path
                        data-title="Республика Тыва"
                        className="rf-empty"
                        data-code="RU-TY"
                        d="M457.9,424.1l-2,2l-1.6,3.2l-1.7,1.7l-0.5,3.3l1.6,1.9v2.8
        l2.2,2.2v3l-3,2l-2.9,2.9h-2.4h-3.7l-3.4-0.9l-3.5,0.6l-2.5-0.8l-3.7-1.3l-2-2l-2.4-2.4l-2.9-0.9l-3.4,1.4l-2.2-2.2l-2.7-1.2
        l-2.2,2.2h-2.8l-0.9,2.7H406l-4.2,2.7l-2.5,2.5h-2.5l-0.5,2.7l-1-1.8v-2.1l-1.9-1.9l1-1l2.1-0.5l0.6-1.8l-2.3-2.2l-2.9-2.9l-0.9-2.8
        l-0.4-2.9l1.1-1.1l2.2,0.6l2.5,0.2l2.4-2.4v-2.8l2.7-1.4l2.2,2.2l2.9,1h2.9l2.6,1.3h2l2.7-1.1l3.7-3.2l2.7-0.8v-3.2l1.9-1.9v-2.1
        l2.9-0.8l0.8-4.6l1.2-1.2l3.3,0.6l2.4-2.4l2.8,0.1l1.1-1.1l1.4,0.5l0.7-1.6h2.3l1.9,1.4l2.9,0.4l2.4,2.3l1.6,0.8h2.8l1.1-1.1
        l1.3,1.3l1,1l-1.5,1.5v2.2l-1.4,1.4l0.5,2.2l1.2,1.2L457.9,424.1"
                    />
                    <path
                        data-title="Амурская область"
                        className="rf-empty"
                        data-code="RU-AMU"
                        d="M675.1,379.6l-1.5,1.5l-2.9-0.8l-2.4-0.6l-3.7,0.5h-3.4l-1.4,1.4
        L656,383l-3.8-0.8l-1.6-3.1l-1.8-1.8c0,0-2.2-1.3-2.2-1.8c0-0.5-2.4-2.4-2.4-2.4l-3-0.5l-1.3-3l-2.9-2.3l-2-2l-2.8-0.8l-1-2.7
        l-2.5-1.1l-1.5-1.5l-5.1-0.5h-2.7l-3,1.6l-3.8-0.6l-2.9,1h-3.2l-4.3,3.2l-2,2l-1.8-3.2l-0.8-1.6h-2.2l-0.6-2.7l-1.1-1.6l1.9-1.1
        v-0.9h-1l-1.7-1.8l-1.4-0.2l0.3-3.2l-0.8-1.5l-0.9-2.5l-2-1l-1.5,2l-1.5-0.6l-0.1-2.2l-1-1l-1.5-0.4l-1.6,1.6l-0.8,1.9l-2.6-0.7
        l-0.8-1.6l1.5-1.9l-0.9-1.5l-1.7,0.6l-2.2-0.6l-1.5-1.5l0-1.5l2.2-2.2l2.5-2.1h2.5l1.1-1.1l1.4,1.4l1.9,0.4l0.9-0.9h2.5l1,1.3
        c0,0,1.6,0.2,1.6,0s0.5-1.9,0.5-1.9l3.2,0.6l1.1-1.1l2.3-0.5l2.1,1l2.7,2.7l2.1-0.9l2.3-0.6l1-1.4l1.7,0.8l1.3-1.3h1.4l1.3,1.3l5-5
        l2.7,0.4v-1.7l3.4-1.3l2.5,0.5c0,0,2.4-1.5,2.5-1.6c0.1-0.1-0.2-2.6-0.2-2.6l4.1-2.5l3.9-3.2l1,0.6l1.6-0.8l1.4-1.8l2-0.3l1.8-1.8
        l2.3-0.4l2.3,1.3l-0.9,2.2c0,0,0.2,1.7,0.1,1.7s-1.6,2.2-1.6,2.2v3.9l-0.9-0.1l-0.7,1.7l1.4,2.3v1.7l-1.5,0.9l-0.9,2.1l1.6,1.9
        l3.1,0.4l1.1-1l2-0.6l3.5,4.8l1.5-1.4l1.6,0.1l2.3-3.4l2.4-0.9l0.8-0.8l-0.5-2.4l0.6-2l2.5-0.5l1.3-1.3l1.4-2.4l2.4-0.2l2.2,2.2
        l0.6,3.8l2.3,1.6l-0.9,3.1l-4.1,1.5l-2.2-0.1l-1,1.4l2.6,2.6l0.1,1.7l-2.3,2.5l-0.3,3.7l-2.2,2l-0.1,1.7l-1.7,2.1l1.5,2.3l-1.2,2.2
        l0.5,5.1l1.4,1.4h1.3l1.4,2.2l1.1-1.1l2.8,0.3l0.5,2l2.4,1.7l0.2,1.8l1.1,0.3l1.4,1.1l0.1,3l-1.2,0.5l0.6,2l-0.6,1.5l0.3,2.8
        L675.1,379.6z"
                    />
                    <path
                        data-title="Еврейская автономная область"
                        className="rf-empty"
                        data-code="RU-YEV"
                        d="M703.4,368.4l-3,1.6l-0.8,2.5l-2,3.3l-2.3,2.9l1,3.2l-2.7,2.2
        l-2.7,1.5l-2.3,0.8l-0.6,2.3h-2.4l-2.4-1.4l-1.8-0.6v-2.9l-2-1.8l-1.6-1.6l-0.3-2.8l0.6-1.5l-0.6-2l1.2-0.5l1.3,0.3l1.3-2.1l1.2-1.2
        l-0.2-2.7l2.1-0.3l2.2,0.4l2.9-1.4l2.1,1l2.5,3l1.6-2.2c0,0,3.3-0.6,3.5-0.8c0.2-0.2,1.3-2.4,1.3-2.4l2.6-0.3l2.2-0.9l-0.2,2.1
        l0.8,0.8l-0.3,1.5H703.4L703.4,368.4z"
                    />
                    <path
                        data-title="Приморский край"
                        data-code="RU-PRI"
                        d="M735.3,360.2l1.4,3.7l1,4.1l0.8,3.7l0.8,4.9v4.8l-0.8,2.5v4.9v6.1
        l-0.8,4.6l0.8,4.7l-0.3,6.1v2.9l-2.5,4.2l-1.6,3.5l-1.9,3.5h-1.6l-3.2,1.3l-2.7,1.5l-1.8-0.8v-1.7l-1-1l-1,2.2v2.3l-1.1-1.1
        l-1.6-1.6v1.3l-0.5,3.2l0.5,2.2l-0.7,2.5l-1.1,1.1v1.3v2l-2.4-0.1l-1-1l0.9-2.9l1.5-3v-2.5l-1.3-3l-2.7-2.7l-1.4-1.4l-1.1-2.9
        l-2.4-2.4l-2.3-0.8l-0.1-2.5l1.6-1.6l1.4-1.4v-2.5l1.8-1.8l2-1l2.1,0.7l1.8-1.8l1.1-2.3l-2-3.2l1-2.7l-1.3-2.8l-0.3-2.9l-1.8-2.4
        l0.5-2l-1.4-2.4l2.5-0.3v-1.1l-1.4-1.4l0.3-2.4l2.2-3l1.8,0.5l0.5,0.5l1.7-0.1l0.6-0.9l2.6,0.4l0.2-0.8l2.6-0.4l2-2.5v-3.6l-1.5-0.4
        l0.4-2.1l3-1.1l0.8-1.8l0.3-2.5l-1-2.4l-1-0.3l-0.8,1.6l-2.1,0.2l-0.6-1.8l-2.3-0.3l-0.4-1.7l2.5-2.1l0.9-3.5l1.5-0.2l1.8,1.4
        l1.4,1.4l1.8-0.1l0.8,1.6l-0.6,1.3l2.2,1.6l2.1-1.8L735.3,360.2"
                    />
                    <path
                        data-title="Магаданская область"
                        className="rf-empty"
                        data-code="RU-MAG"
                        d="M713.7,143l1.6,1.6v4.1l1.3,3l0.3,4.8l-0.9,2.9l-1-2.5l-2.3-1.1
        l0,3.2l-1.9-1.9l-1.3-1.3l-1-2.2l-3.5-3.3l1,2.8l-1,3h-3.4v2l-1.1,4.2l-1.4,1.6l0.1,5.6l2.3,1.5l-0.4,3.5l2.3,3.5v3.8l0.7,5l2.1,2.1
        v2.3l1.8,1.8l1.6-0.3l1.5-1.3l1.1-1.1h1.9l-0.1,2.4l-2,0.6v3.4l-3.8,0.6l0.9,4.4l-0.4,1.9l-2.4,1v2.7l-0.9,2.4c0,0-3,0.7-2.4,0.1
        c0.6-0.6-1.1-1.1-1.1-1.1v-1.6l0.5-1.6l2.1-0.7l-1-1l-2.3,0.9l-2.4,0.4v1.1L695,208l-1.8,2.2l-2,0.5l-1.6,1.6l-0.6,1.9l0.6,2.4
        l1.4,1.4l-1.6,1.8l-2.7-1l0.8,2.7l-2,2l-1.8,1.6l-0.6-2l-2.8-2.8h-1.5l-1.1,1l-1.5-0.8l-2.2-0.1l-1.9,2.3l-1.7,0.1l-1.4-2.1V218
        l2.4-1.3l-1.9-2.6v-1.9l-1.7-0.6l-2.9-1.8l-1.7-0.9l-4.8-0.3l-2.6,3l-2-0.1l0.4-2.4l-1.9-1.9l-0.5-1.5l-1.7-1.7l-3-1.5l-0.8-1.8
        l-2.1-2.8v-1.5l-1-0.5l0.5-2.5l1.2-1.2l-1.8-1.1l0.4-2.2l3.3-1.3v-1.2l2.5,0.1l-0.2-2.8l-1.9-0.6l-1.3-1.7l1.6-1.5h1.6v-2l1.6-1.1
        v-1.4l2-0.5l1.2,1.4l1.7-0.2l0.9-1.8l-0.6-1.6l1.7-2.8l0.6-1.8l1.7-0.3v-1.6l-0.9-2.3l-2.1-1.2l-2-2l-0.2-4.8l-1.9-1l-1.3-1.8v-2.1
        l1.5-0.3l-0.7-2.1l-2.1-0.6l0.4-1.9l2.2-0.9v-2.2l1-1h2.5l0.2-2.6h3.2l1.3-1.3l-0.8-2.6l1.1-2.1l-1.5-2.5l2.8-1.2l1.3,1.3l1.5,2.2
        h2.1l2.5-1l0.8-1.6l2.6,0.6l3-0.3l2.7-3.7l4,0.6l4.2-4.9l3.2,0.3l0.1,1.3l0.8,0.8l-0.6,1.8l3,1.3l0.4,1.9l3-0.6l1,2.3l2.1,0.9
        l0.1,1.7l1.6,1.6l1.9-0.5h2.2l0.4,1.5h1.4l1,1.2l-0.8,2.8h1.1l0.9-1.3l0.9,0.9v1.7L713.7,143z"
                    />
                    <g data-title="Архангельская область" data-code="RU-ARK">
                        <path d="M167.7,189.4l-0.5,0.7l0.8,1l1-1L167.7,189.4" />
                        <path d="M348.1,78l-1,0.3l0.8,1v1h1.3v-1.8L348.1,78" />
                        <path
                            d="M274.9,191h-1.3l-1.4-2.1l-0.8,0.5l-2.4-2.3l-1.8-2l0.7-2.1
            l1.8-0.6l0.7-1.1l-1.3-1l0.1-2.8l-0.8-0.2l-0.8-2.1l-1.7-0.1l-1.4-2l0.5-2.5l1.8-2.5l2.2,0.2l0.7,0.8l0.8,0.3l0.3-1.5l2.6-0.8
            l2.1-2.2l-1.4-1.5l1.8-1.2l2.1,0.8l0.6-1.7v-1.7h2.6l1.3,1.3l1.3-1.3l0.3-1.2l2.9-0.6l-1-1l-2.5-0.9v-0.9l2.9-0.8l3-0.8l1.5-1.5h2
            v-1.4l2.8-0.1l-1.5-1.5l2.4-0.1l0.9,0.9l-0.1-2.3l1.2-1.2l-1.3-1.5l0.8-1.3l1.4,1.4h1.7v-1.7l2.5,0.9l1.3-1.4l1.3-1.5h1.6l2.8-1.3
            l2,0.7h2.9l1.9-1.9l2.2,0.2l1,1.5h2.4l1.2,1.1l2.1-0.6h2.9l1.9-1.9l2.2-1.2l3-1.2h2.8l1.6,2.5v2.8l-2.4,2.2l-2.2,2.1l-2.9,0.2
            l-3.4,1.6l-3.1-1.1l-1.5,1.5l-3.6-0.1H317l-1.7,1.8h-3.6l-2.6,1.5l0.8,1.8l-1.8,0.7l-2.2-0.2h-2l-0.2,1.3l-2.1-0.6v2.1l-2.1,1.5
            l-1.7,1.3v-2l-1.4,1.3l0.3,2.3h-1.4l-1-1v1.1v1.7l-1-1l-1.3,1.3l-1.1,1.1h-1.5l-1.1,1.5l-1.5,2.5h-2.4l-2,1.6v2.2l-2,0.8l-0.7,2.3
            l-1.6,1.6v2.9l-0.8,2.1l1,3l-0.9,3l1.3,2l0.6,2.1l-0.7,0.7l-2,0.9v-1.5L274.9,191"
                        />
                        <path
                            d="M347.8,86.1l-0.7,1l-2.5,0.5l-1.6,0.9l1.2,0.6l-0.6,1.3l1,0.3h2.1
            l1.7-1.7l1.2-1.2l-0.4-1.9L347.8,86.1"
                        />
                        <path d="M339.9,84.4l-0.5,0.5l1,1l1.8-0.7v-1.5h-1L339.9,84.4" />
                        <path
                            d="M338.6,87.4l-1.4-1.6l-1,1l-0.8,1.5v1.9l1.6,0.3l0.4,1.1l2-0.5
            l1.3-1.3l1-1l0.5-1.6h-1.4L338.6,87.4"
                        />
                        <path d="M332.9,94l-0.6,0.6v1.2h1.3l0.5-1.5L332.9,94" />
                        <path
                            d="M330.3,89.2l-0.7-0.7h-2.1v1.4l-1.1,1.1l0.7,0.7l2.3-0.5l0.9,0.9
            l2-0.5l0.6-1.3l1.4,0.5l-0.8-1.7l-1.3-1.3L330.3,89.2"
                        />
                        <path d="M340.8,73.3l-0.6,1.1l1,1l1-1L340.8,73.3" />
                        <path d="M338.7,75.9h-1.5v1.7l1.6,1.6v1.3l1.4-0.7l-0.9-1.8L338.7,75.9" />
                        <path d="M329.2,80.9h-1.3l-0.3,0.3l1.1,1.1L329.2,80.9" />
                        <path d="M330.3,83.1l-0.9,0.9l0.9,1l1-0.1L330.3,83.1" />
                        <path d="M331.2,78.9v1.2h1.3l-0.3-1.4L331.2,78.9" />
                        <path d="M333.1,82v1.1h1.1l-0.9-0.9L333.1,82" />
                        <path d="M334.1,84.5l0.5,0.5l0.9-0.9L334.1,84.5" />
                        <path d="M335.5,79.5v1.3l1.1,0.1v-1.4H335.5" />
                        <path d="M333.8,76.8l0.7,1.2l0.6-0.6l-0.6-0.6H333.8" />
                        <path d="M321.5,84.8l-0.7,0.7l1.2,1.2l1.4,0v-2.3l-1-1.2L321.5,84.8" />
                        <path d="M324.6,83.3l0.5,1.3h1v-1.1L324.6,83.3" />
                        <path
                            d="M319.3,75.7l-1,1v1l-1.1,1.1l-1.9-1.4l-1.6,0.9v1.1l-2-1.4
            l-1.5,0.6l0.5,2.3l1.4-0.5v1.5l1.5,0.4l1.4-1.4l1.9-0.8l1.6,0.5h2.4l1.7-1.7l-1.6-1.6l1.1-1.1l-1.9-1.1L319.3,75.7"
                        />
                        <path
                            d="M313.9,73.3h-2.1l-1.2-1.2l-0.9,1.7l1.1,1.1l0.4,1.3l2.5-0.9
            l0.9,0.9l1.6-0.7l0.7,0.7v-1.4l-1.1-1.1h-1L313.9,73.3"
                        />
                        <path d="M315.8,83.3H315l0.3,1.2l1.7,0.1L315.8,83.3" />
                        <path d="M316.1,85.8l-0.9,0.9l0.7,0.7l1.6,0.4l-1-1L316.1,85.8" />
                        <path d="M323,73.6v1.2h1l1-1L323,73.6" />
                        <path
                            d="M206.3,204.1l-2.2,2.2l-1.6,2v-2.8l1.8-1.8l-2.2-3.3l-1.5-1.5
            h-2.3l-3.4,1.1l-3-1.6l-3.7,1.4l-3.9-0.6l-0.1,4.4l-2.2,1.8l0.9,2.2l-1.1,1.1l-3.6-1.1l-1.4-2.9l-2.2-2.2l-0.7-3.7l-0.8-5.7h-2.2
            l-2.2,2.3v2.2l-0.3,2.8l1.6,1.6l-0.6,3.2l-3.2,2.3l-2.8-2.8l-2.6-2.6l-0.7,0.8l-1,1.5l-1.3-0.9l-0.6,0.6l-1-1.3l-0.8,0.9l0.1,2
            l-0.8,1.8l-1.6,0.8l-0.6,1.4l-0.3,3.2l2.4,2.4l-1,2.6l-2.1,0.6l0.3,1.7l-0.4,2l-2.5,2.5l-0.9,0.2l-1,1.1l-1.9,3.9l0.3,1.2l-1,1
            l0.7,3.5l1.5,0.9v1.6l2,1l0.5,1.3l0.8,0.5l1.2,1.2h1.3l1.1,1.1l0.5,1.4l2.5,0.1l1.9,0.5l0.9,0.9l1.6,0.4l0.6,1.6l1.5,1.5l1,0.7v1.3
            l1.1,0.4h2.2v-0.8l1,0.3h1.4v1.6l0.8,0.9h0.8v1l1.5,0.8l1,1l1.3-1.3l0.4-1.1l1.4,0.6l1,1.6l-1.1,1.1l1.5,0.8v0.8h1.1l1.4,0.6
            l-0.6,0.6l0.8,1.2l0.8,0.8l1.4-1.2l1,0.3l0.7,1.1l0.5,0.5l1.2,1.2l-0.4,1.4l1.1,0.6l1.6-0.4l-0.1-0.9l1.1,0.3h2l0.3,1l1.3,0.3
            l1.1-1.3l1.3-1l1.1-1.8l-1.4-1.4l1-1l1.5-1l0.2-1.1l2-1.2l1.7-1.7l1.1-0.5l1.6-1.6l-1.1-1.1L203,250l-1.3,0.6l-0.8-0.8h-1.1
            l-1.6,0.3l-0.7,1.6l-0.8-0.3l-1.1,1.6l-1.1-1.1l-1.2-0.4l-1.3-0.6l-0.2-2.2l1.5-0.8l-0.3-1.4l0.6-0.6l0.5-1.2l1.5-0.4l1.8,0.4
            l0.3-1.2l-0.3-2l-0.9-0.9l0.5-1.9l1.7-1.1v-1.8l0.5-1.8l-1.3-1.3l-1.3-1.9v-1.5l-0.3-1.7h1.6l1.8,1.3l1.5,0.5l1.1,1.1l2.2,0.4
            l1.8,1.1l2,2l0.4,2l1.5-0.8l1.7-0.6l1.3,0.8l1.6,0.4l1.6,1.3h1.1l0.8,0.8l1.1-1.1l1.1-2l-1-1l-0.4-1.7l0.8-1.7l-0.6-1.2l-1.4-1.4
            l-0.5-1.1l1.1-1.1l0.9-0.9v-0.9l0.9-0.9l0.7-1.9l1.4-1.4l-1-1l-2.4-1.1l-0.3,0.8l-2.2,0.8l-1.2-0.4l-1.5-1.8l-1.1-1.1l-0.5-2.2l-2-2
            l-1.1-1.5l-0.7-2.7l-1-2.1L206.3,204.1"
                        />
                    </g>
                    <g
                        data-title="Сахалинская область"
                        className="rf-empty"
                        data-code="RU-SAK"
                    >
                        <path
                            d="M705.1,284.6l-0.9,0.2l-0.7-1.9v-1.6l2.8,0.8l1.4,0.7l0.8,1.8
            l1.5,1.5l2.7,0.5l0.7,1.8l3.1,2h1.6l1.3,1.9l1.1,3l1.7,1.7l3.2,3.3l2.1,0.5l2.9,1.1l2.7,2.4l3.7,2.1l2.4,2.1l3.2,1.6l2.7,1.6
            l1.8,1.4l3.1,1.2l-0.4,1.4l-1.1,1.1l-2.1,0.7h-1.6l0.4,2.3l-0.4,1.6v1.3l1.9,2.9c0,0,1.8,2,1.4,2.3c-0.3,0.3,0.1,3.4,0.1,3.4
            l1.4,1.4l2.2,2.2l1.9,1.2h2.8l1.6,1.6h1.9l1,2.1l2.4,0.1l1.5-1.5l2.6,1.5l1.5,2c0,0,0.5,1.3,0,1.3s-3.5-1.4-3.5-1.4l-1.3,1.3h-2.8
            l-0.9,2.2l0.5,2.2l1.8,1.8l1.3,2.9l-1.8,1.4l-1.4-1.8l-1.9-1.9l-3.3-3.1v-2.1l-2.2-2.2l-2.1-2.1l-1.4-1.9l-1.8-3.4l-2.2-2.1
            l-2.3-1.3l-2.2-0.7l-1.9-3.9l-4.1-5.6l-3.3-3.3l-2.7-2.7l-2.4-3.3l-1.5-2.5l-4.2-1.9l-3.3-1.4l-2-0.2l-2.6-3.6v-1.6l-2.8-2.9
            l-1.5-2.8l-3.1-1l0.8-2.2v-1.6l2.5,0.8l0.4-2.2l-1.5-1.5v-0.8l-1.5-1.5L705.1,284.6z"
                        />
                        <path
                            d="M797.8,341.9l0.4,2.3l0.2,2.2l0.9,0.3v-1.8l0.7-1.4v-2
            L797.8,341.9"
                        />
                        <path d="M805.3,343.2v1l1.4,0.8v-1.6L805.3,343.2" />
                        <path
                            d="M800.8,334.3l1.3,0.5l0.4-1.8l-0.7-3.5l1.2-1.2l-0.2-2.5l-1.9-0.5
            l-0.5,2.9l0.7,0.7l-0.6,2.1L800.8,334.3"
                        />
                        <path d="M804.1,319.8l-0.5,0.5l0.4,1.9l0.9-1.3L804.1,319.8" />
                        <path
                            d="M805.4,313.1l0.5-2.8l-0.8-1.8l0.4-1.9l1.5-1.5l0.4,2l-0.3,2.1
            l0.4,2.4l-0.8,1.8L805.4,313.1"
                        />
                        <path d="M806.9,288.7v2.4v2h1.5l-0.4-1.9V290v-1.4L806.9,288.7" />
                    </g>
                    <g
                        data-title="Камчатский край"
                        className="rf-empty"
                        data-code="RU-KAM"
                    >
                        <path
                            d="M745.7,210.4c0,0,1.1,2.5,1.6,3l3.4,2.3l3.5,1.9l5.4,1.6l2.5,0.5
            l2.3,1.5l1.4,1.9l4.8,2.5l4.2,1.8l4.4,2l2.3,2.3h2.5l1.8,1.8l2.3,1.2h3.7l1.4,1.9v-2.5l-0.6-3.4l-1.3-5.3l-0.5-4.1l-2.4-2.3
            l-3.3-3.3l-2.2-0.9l-1.3-4.6l1-2.3v-2.3l2.8-0.4V204l-3.7-0.9L781,202l-2.2-0.8l-2.5-3.9l-0.9-5.1l1.3-2.8l2.2-1.4l1.5-1l-0.9-2.3
            l-2.5-2l-4.3,0.5l-1.8-1.8l-2.3-0.6l-2.7-4.4l-0.8-3l2.5-0.8l1-2.4l-2.5-2.5h-2.1l-1.3,1.3l-3.3-0.7l-3.3-2.3l-0.6-2.9l-3.3-3.3
            l-0.9,1.9l-1.2,1.2v1.1l1,2.2h-3.5l-2.1-2.1l-3.5-1.8l-0.4-2.8l-0.5-3.5l-2.4-2.4l-1.8-1.8l-1.5-1.5l-1.9-3.2l1.4-1.4v-3.3h1.5
            l2.3-2.3l-2.3-1.1v-2.4l-1.4-3.7l1.5-0.1l1.8,1.8l2.4,1.1v-2l-2.2-3.3v-1.9l1-3.5l-1.4-1.4l2.7-3.8l1.6-3.8l3.2-1l2.3,1.1l2.2-1.9
            l-1.4-1.4l-2.2-2.4l-1.3-5.1v-3.7l-2.4-2.2l-1.3-3.9l-0.6-4.1l-1.3-3l-0.8-3.5l-1.4,2l-2.2,0.2l-1.6-1.6l-1.9,0.6l-0.6,3.2l-0.9,3.2
            l-1.9,2.9c0,0,1.1,1.1,1.1,1.7s-1.3,3.9-1.3,3.9l-1.3,2.8l-2.1,1.3l-1.4-1.4l-1.9,0.6l-1.7,1.7l-2.2,0.4l-1.9,2.6l-1.5-1.5l-0.8-2.1
            l-1-2l-0.7-2.6l-1.6-0.5l-2.2,1.5l-1.3,1.3l-1.8-0.8l-3,0.5l0.6,1.9l-1,1.5l-1.3-0.4l-3,0.9v2.2l-1.4,1l-0.8,2.6l-2.1-0.2l-2.5,1.9
            l0.9,0.9l-1.3,1.3l-2.1,1.1l-1.1,2.3v2.2l0.9,1l3.2,0.3l0.1,1.3l0.8,0.8l-0.6,1.8l3,1.3l0.4,1.9l3-0.6l1,2.3l2.1,0.9
            c0,0,0.1,1.3,0.1,1.7c0,0.4,1.6,1.6,1.6,1.6l1.9-0.5h2.2l0.4,1.5h1.4l1,1.2l-0.8,2.8h1.1l0.9-1.3l0.9,0.9v1.7l2.6,0.3l1.2-1.7
            l1.1,0.1l0.3-1.6h-2.5l-2.1-2.1l-2.2-2.2l-1.5-1.4l0.8-2v-2.7l2.8-1.5l2-2l0.8,0.8l-2.4,2.3v2.9l2.7,1.6l1.8,2.3l1.5,1.5l1.6,0.6
            h1.6l1.6,3.2l1.5,1.5v2.2v1.8l-0.9,3l0.1,2.5l1.5,1.3l1.5,4.7l1.3,3.2l1.1,3.9l1,4.2l2.4,3.2l1.5,4.7l0.6,4.1l1.3,3.8l-1,1l-1.1,1.1
            l1.2,2.1l-0.9,1.9l-1.1,1.1v1.1l2.3,0.5l2.2,3l0.4,2.9l1.8,1.8l0,2.7l2.5,2.7L745.7,210.4z"
                        />
                        <path
                            d="M743.1,147.2v2.4l-0.2,2.2l1.3,0.9l0.9,1.1l0.1,2l1.8,0.7l-0.4-2
            l-0.8-1.5l0.8-2.5v-3.1l-2.1-0.9L743.1,147.2"
                        />
                        <path
                            d="M785.7,158.7l-1.5,0.5l0.8,1.6h2.6h2.6l1.6-1.6l-2.1-0.7l-2.1,0.9
            L785.7,158.7"
                        />
                        <path
                            d="M793.3,239.5l-0.7,2l-1,1l1.2,1.2l0.7,1.5l-0.2,2l-0.1,2.5
            l1.2,1.2l1.3-0.7v-2.6l1.2-1.2l-0.9-2.2l-1.5-1.5l0.1-2L793.3,239.5"
                        />
                        <path d="M798,256.6v1.6l1.1,0.5l0.8-1L798,256.6" />
                        <path d="M795,153l-1.4,1l0.8,0.8l1-1l1-1L795,153" />
                    </g>
                    <g
                        data-title="Чукотский автономный округ"
                        className="rf-empty"
                        data-code="RU-CHU"
                    >
                        <path
                            d="M738.9,84.7v-4.6l-0.9-4.1l-2.2-3.3l1-1l-3-2l0.8-1.1h2.7l1.6-3.5
            l2.5-1v-2.7l-1.8-1.8l-1-2.2l-2,1.5l-1.4-1.1l-1.6,0.3l-1.3,1.3H730l-0.6,1.2h-2.3v-1.8l-2.8,0.1l-1.5,0.3l-1.9-0.1l-0.8,1.3
            l-1.6,1.6l-2.7,0.1l-1.5,1.1v2.4l-1.6,0.6l-1.3-1.3l2-2.4H712l-2.2,0.9l0.9-2l2.7-1.1h1.9l2-3.3l-0.5-2.9l-1-3.7l-2.1-2.1l-2-2
            l-1.1,1.1l-0.1-2.4h-1.9l-2.8,0.8l-1.7-1.7v-1.7l-2.2-0.4l0.8-0.8l1.1-0.5l-1-1l0.2-1.2l1.3,0.8l1.4,1.1h2.4l1.6-1.3l1.6,1.3
            l2.1-2.1v-3.2l-1-1.9l2.2-3.2h2.4l1.9,0.8l2.7-0.9l1.4-3l2.7-2.9l2.9-0.3l1.1-3.3l-2.2-0.6v-1h2.7l-0.9-2v-1.6L728,17l-1.4,0.4
            l-1.6-2.5l-2.3-2.3v1.3l-3-1.1l-1.4,1.4l-2.3-0.4l2.9-1.9V9.1c0,0,0.8-2.3,0-1.5c-0.8,0.8-2.7,0.8-2.7,0.8l1.4-2.9l-1.3-1.3
            l-1.6-1.4c0,0,0.8-2.9,0-2.2S711.7,2,711.7,2l-2.3,3.2h-0.9l-3,1.1l-1.8,1.8l-1.3,1.8l-0.3,2.3l-1.4-0.1v1.1l2,0.5l-2.4,1.6
            l-0.8,2.3l2.3,0.6l3.5,0.3l3-0.6v1.5l-3,1.6l-1.7,1.7l-2.6-2l-2.9,0.9l-2-2l-1.8,1l-2.8,1.3l-2.3,2.4l-4.3,1.3l-2.3,3.3l-3.2,1.3
            l-2.6,2.6l-2.5,2.5l-2.7,1.6l-3.8,1.6l-2,2l-4.3,4.3l-3.9,3.7l-1,3.7l-2.2,3.2l-0.8,2.3l0.8,2.2H653l-2,1.1l-0.3,2.7l-2.3,1.1
            l-0.6,2.4v1.4h2.5l0.8,1.8l3.2-0.5l4.3,1.9l1.4,0.3l0.8,2l-0.6,2l-2.5,0.6l-2.8,1.3l-2,0.8h-2.9l-1.9-1.9l1.8-1.8l-2.4-2.4l-1.3,1.3
            l-1.4,2.5l1.8,2.5l-0.5,1.5l1.3,1.3l-0.8,3l-3.2,2.3v2.5l-1.6,1.6h-1.9l-1.1,2.2v4.2l1,1.4l3,1l2.5,0.3l0.6,1.6l2.3,0.9l2,3.3
            l-2.6,2l-1.2,3v2.5l-1.7,1.2v2.6l-1.5,2.4l1,2.5l2,1.1v2.9l2.5,0.4l2.4,1l2.6-0.9h2.1l1.5,1.5l1.3,2.7h1.4l2.8-1.2l1.3,1.3l1.5,2.2
            h2.1l2.5-1l0.8-1.6l2.6,0.6l3-0.3l2.7-3.7l4,0.6l1.8-1.8l2.4-3.1l-0.9-1v-2.2l1.1-2.3l2.1-1.1l1.3-1.3l-0.9-0.9l0.6-0.6l1.9-1.3
            l2.1,0.2l0.6-2.4l1.7-1.3v-2.2l3-0.9l1.3,0.4l1-1.5l-0.6-1.9l3-0.5l1.8,0.8l1.3-1.3l2.2-1.5l1.6,0.5l0.7,2.6l1,2l0.8,2.1l1.5,1.5
            l1.9-2.6l2.2-0.4l1.7-1.7l1.9-0.6l1.4,1.4l2.1-1.3l1.3-2.8l1.3-3.9l-1.1-1.7l1.9-2.9l0.9-3.2l0.6-3.2l1.9-0.6l1.6,1.6l2.2-0.2
            L738.9,84.7z"
                        />
                        <path
                            d="M650,21.1l-1-0.4l-0.9,0.9l-1.9,1l-1.6,1.6l0.1,2.7l-0.7,0.7
            l0.3,2.2l1.3,1.8l1.3,3l2,0.4l1.4-1.4l1-1.9l-1.1-1.2l1.7-2.4l-0.3-2.6v-3.1L650,21.1"
                        />
                    </g>
                    <path
                        data-title="Калининградская область"
                        className="rf-empty"
                        data-code="RU-KGD"
                        d="M21.6,195.1l2,0.4l1.7-0.5l0.6-1.2l1.5,0.2l1.4,1.4l0.1,2l1.2,0.4
        l0.2,1.5l1.8,0.3l0.4-1.4l-1-1l0.8-0.8l0.5,0.5l1.7,1.4v2.2l-0.2,1.9l1,1.6l1,0.7l0.4,2.5l-0.9,1.9l-4.2,2.1l-6.4-7.6L21.6,195.1"
                    />
                    <path
                        data-title="Калужская область"
                        data-code="RU-KLU"
                        d="M73,268.4l-1.2-1.9l1.7-0.4l1.8-1.8H77l1.3,1.3l0.4,1.5l1.8,0.2
        l0.6-0.6l1,0.3l1.7,1.3l1.6-1h1.8l2.3-0.5l1.8,0.5l1,1.8l1.2,1.2h2.1l0.8,1.5l0.8,1.4l-0.9,1.3l-0.7,1.3v1.9l-1.9,1.3l-1.8-1.2
        l-0.7,0.7v1.4l-1.7,0.3l-1.5-1.5l-1.3-0.7l-2.2,0.6l-1.1,1.1l-1.1-0.4l-1.8,2.4l-2.8-1.1h-2l-1.8-0.6l-0.3-1.8l-1.2-1.2v-1.5l1-1
        v-1.9l0.5-1.9l-1-1L73,268.4"
                    />
                    <path
                        data-title="Новгородская область"
                        className="rf-empty"
                        data-code="RU-NGR"
                        d="M118.4,243.7l0.9-0.9l0.3-1.9l-1.6-2.6l-1.8-0.7l0.5-1.1l0.8-1.6
        l-0.7-1.9l-0.3-1.9l-1-1l-1.5-0.5l0.8-1.6v-1.5l-1.6-0.7l-0.2-1.5l-1.8-0.1l-1.1,1.1l-1.4-1.7l0.4-3.3l-1.1-0.3l-1.3-1.3l-1.2,1
        l-1.3,0.3l-2-0.8l-2-0.2h-2.9l-0.9-1.8l-1.1,0.5l-0.9,0.9l-1.7-0.2l-2.1,0.2l-1,1v1.6l-0.9,0.9l0.4,1l1.3,1l-0.6,1.8l-1.7,0.8
        l-1.3,1.3l-1.1,0.6v1.8l-1.1,0.6l-1,1l1.1,1.1l0.8,1.6l1.1,1.1H89l1.1,1.1l1.9-0.1l1.5,0.9h2.7l1.4,1.4l1.8,0.2l1.1-1.1h2.1l1.4-1.4
        h1.7l1.4,0.7l1,1l0.5,1.8h1.8l1.2,1.5h1.6l0.3-1.1l1.1,1.1l1.7,0.8L118.4,243.7"
                    />
                    <path
                        data-title="Тверская область"
                        data-code="RU-TVE"
                        d="M100.3,257.4v1.6l0.7,0.1l0.2,1.4h1.9l1.1,0.7l1.6,0.9h2l1.3,1.3
        h2.1l1.7,1l1.6,0.5l1.5-1.4l2.5-1.6l-0.9-2v-1.2l1.4-1.4l-0.6-2.2l0.7-1.6h1.8l0.8-2.6l2.5-1.1v-2.5l0.7-2.1l-1.8-0.6l-2,0.4
        l-1.5-0.6l-1.1-0.7c0,0-1.5-0.8-1.8-0.8c-0.3,0-2-0.8-2-0.8l-1.1-1.1l-0.3,1.1h-1.6l-1.2-1.5h-1.8c0,0-0.1-1.8-0.5-1.8s-1-1-1-1
        l-1.4-0.7H104l-1.4,1.4h-2.1l-1.1,1.1l-1.8-0.2l-1.4-1.4h-2.7l-1.5-0.9l-1.9,0.1l-1.1-1.1h-2.2l-1.1-1.1l-0.8-1.6l-2,1.6l-0.2,1.6
        l-1.1,1.1l-0.3,2.7l-0.7,1.5l-0.8,1.3h-1.8l-0.8,2.2l0.2,0.8l1.7,1l0.5,2.6l1.6,1.6l1.7,1l1.2,1.2l2.5,0.6l2-0.6l2.2,0.6v1.3l1,1
        l1,1.9l1.4,1.1l0.8,1.4l0.8-1h1.5l1-1.4L100.3,257.4z"
                    />
                    <path
                        data-title="Московская область"
                        data-code="RU-MOS"
                        d="M112.2,278.6l-0.6-1.2v-1.6l-1.2-0.5l-0.6-1.7l1.4-1.1l-0.1-1.7
        l1-1l0.5-1.6l1.4-0.7c0,0-0.5-1.4-0.4-1.6s0.9-1.1,0.9-1.1l-1.6-0.5l-1.7-1h-2.1l-1.3-1.3h-2l-1.6-0.9l-1.1-0.7h-1.9l-0.2-1.4
        l-0.7-0.1v-1.6l-2,0.8l-1,1.4h-1.5l-0.8,1l-0.6,2.1l-2.3,1l-1.7,1.7l-0.9,1.4l1.8,0.5l0.8,1.6l1.3,1.4h2.1l0.8,1.5l0.8,1.4l-1.6,2.7
        v1.9l1.1,1.8l1.6,0.3v1.4l-0.5,1.6l0.5,1.6l-0.6,2.1l0.2,1.7l1.9-0.3l0.3-1.3l1.6-0.3l0.5,0.5l2.3-0.1l0.7-0.7l2,0.4l1.2,0.4
        l1.3-0.3l0.6-0.6l1.5,0.1h1.5l0-1.8l0.8-0.4l0.4-1.3l0.2-2.4l-1-1L112.2,278.6z M105.9,272.2l-0.8,1.1l0.3,1.3h-1.7l-0.5-0.5
        l-0.5-0.5l-0.9,0.9l-1.3,0.1l0.5-1.4v-1.1v-1.3l0.9,0.1l0.8-1.2l-0.3-0.7l0.8-0.7l1.1-0.3l0,0.8l0.4,0.8l0,0.2l1.2,0.5l0.9,0.9
        L105.9,272.2z"
                    />
                    <path
                        data-title="Москва"
                        data-code="RU-MOW"
                        d="M104.7,269.9l0-0.2l-0.4-0.8l0-0.8l-1.1,0.3l-0.8,0.7l0.3,0.7
        l-0.8,1.2l-0.9-0.1v1.3v1.1l-0.5,1.4l1.3-0.1l0.9-0.9l0.5,0.5l0.5,0.5h1.7l-0.3-1.3l0.8-1.1l1-0.9l-0.9-0.9L104.7,269.9"
                    />
                    <g
                        data-title="Ненецкий автономный округ"
                        className="rf-empty"
                        data-code="RU-NEN"
                    >
                        <path
                            d="M299.3,218.6l-1,1.5l-1.2,1.2l-0.8,0.8l1.2,1.2l0.3,1l1,0.3v2v2.3
            h-1.2l-1.5-0.8h-1.5l-2.5,2.2l-2.2,0.1l-1,2.4l-3.4-0.6l-0.9,1.5l-1.5,1.5l-1.5,0.8l-2.8,1l-2.4,0.6l-2.9-1.5l-1.5,0.3l-1.7-1.5
            l-3.4-1.3l-3.4-2.2l-2.5-1l-4.1-1.6l-2-2l-2.5-0.3l-4-2.6l-2-2l-3.1-1.4l-3-2.6l-2.8,0.3l-2.1,1.4l-13.2,0.1l-1-1l-2.4-1.1l-0.3,0.8
            l-2.2,0.8l-1.2-0.4c0,0-1.3-1.5-1.5-1.8c-0.3-0.3-1.1-1.1-1.1-1.1l-0.5-2.2l-2-2l-1.1-1.5l-0.7-2.7l-1-2.1l-1-1.3l3-0.6l2.5-3.7
            v-3.2l1-2.2l3.3-1.6v-2l3.2-1.9v-3.2l-0.5-3h2.2l0.7,1.6l2.9,2.9l2.2,3.3v4.4l-0.8,2.3l-1.1,1.1l-1.9-1.9l-2.7-0.6l-4.1,1.4v3.3l1,3
            v2.5l2.9,0.8l2.8,1.3l3.2-1.5v-2.3l3.3-0.8l3.2,1.5l-0.5-2l4.9,0.9l2.7-0.5l4.8,0.6h2.8l1.4,2.5h1.3v-2.3l4.1-1.3h2.2l0.9,3.3h-2.4
            l-0.8,2.3l-1.9,0.6l2.9,2.7h2l3.8-1.6l3.6,0.7l3.9,3.9l1.3-1.3h2.7l3.4-1.1l1.3,1.3l-0.4,1.8l-2,1.4v2.2l2.4,2l0.2-2.3l1.1-1.1
            l2.5,0.6v-2l2.4-0.4l0.5-3l-0.4-3.7l2.3-1.1l2-0.6l2.3,2.3l1-1l2.6,3.2l3,3l2.4,2.4L299.3,218.6z"
                        />
                        <path
                            d="M282.9,197.8l-1.8,0.9l-1.4,0.5l0.3,3l0.2,1.6l1.3,0.3l0.5,2.4
            l2.4,0.2l0.8-2l-1.2-2l0.1-2.2L282.9,197.8"
                        />
                        <path
                            d="M242.2,187.8l-2.5,0.7l-1.5,2l-1.1,1.5l0.8,2.2l1.8,0.7l1.7,0.7
            l1.6-1.1l2,0.3l1-2.4l-1.6-2.1l-0.1-1.7L242.2,187.8"
                        />
                    </g>
                    <g
                        data-title="Ямало-Ненецкий автономный округ"
                        className="rf-empty"
                        data-code="RU-YAN"
                    >
                        <path
                            d="M295.8,232.2l0.2-1.4l1-0.5l0.6-1.4h1.2v-4.3l-1-0.3l-0.3-1
            l-1.2-1.2l2-2l1-1.5l1,2l3,3l2,3.2l2.2,2.9v2.5l1.8,2.8l2.5-1.5v-2.7l2.9-2.7l-2-0.6l-1.4-3.3l0.5-3.5l-2.4-1.6l-1.3-2.7l1.2-1.2
            l2.5-1.3v-2l0.6-3l1.1-1.5v-1.9l-1.6-0.5l0.9-2.5l1.4-1.8l2.5-0.9l2.7-1.3l1-1l2.2-2.2l1.6-3.7l1.3-3l1.4-3l2.4-1.3l1.5,2.2h3
            l1.5,1.5h2.7l1.1,4.3l-1.3,1.3l-0.6,4.6l-3.6,3.6l-1,3.3l2.2,1.9l-0.8,4.3l-0.6,3.4l-2.5,3.8l-0.9,5.4l-1.4,4.8l-1,2.4l1,3.4
            l2.2,2.2l-0.4,2l-2.8,2.2l-0.5,4.7l-2.8,0.8l-3.3,3.3l-1.9,2.8l-1.8,1l-3.2-0.6l-1.9,1.3l-2.1-1.3l-1.3-1.3l-1.9-0.6v2.4l1.9,2.9
            l1.9,0.9l2.5,1.8l2.2-0.1l1.5,2.2h2.3c0,0,1-1.7,0.4-2.2c-0.5-0.5,1.3-1.3,1.3-1.3h1.6l0.8-0.8l3.2-0.9l2.5-1.4v-2.7l1.1-1.1l2-2
            l1.6-1.6l0.8-3l-0.6-2.7l-0.3-2.4l1.7-1.7l1.7-1.7h2.7l2.9-0.4l0.8,2.5l1.2,1.2l1.5,3.9l-2,2l0.1,3v1.9l2.5,2.5l2.9,0.6v-1.8
            L347,250l-1.1-1.1v-1.5l-0.3-2.3l2-0.6l0.8-1.6l-0.8-2.4l0.9-2l-1.3-2.5l-1.6-1.5l-1-1l-3.1-1.1l-1.8-1.8l-1.5,1.5l-1.9,0.9l-1-1.4
            h-2.3l-0.4-2.4l1.5-1.5l-0.4-4.1l1.8-2.8l1.1-3.3l2.5-2.5l-0.9-2.8l-0.8-2.4l0.3-2.6l-1.8-1.8l2-2l3.2-3.2h2.7l1.5-2.4l1-2.7
            l-0.9-2.2l0.9-1.9h2l-0.6,2.7v3.7l-0.5,3.3l-2.3,2.3v3.2v2.3l2.7,2l1.5,1.3l2-1.2l2,2l2.2,2v-1.5l-0.8-2l-1.5-1.5l-2.3-1.1v-1
            l-1.9-1.9l-0.9-2.8l2.7-1.8l1.3,1.3l2,2l1.5-1.4L355,201l-0.2-1.5l1.5-1.5l0.1,1.5h1.2l0.5,1.4l1,1l1.7,0.4l1.8,1l0.4,1.6l-1.2,1.2
            l0.3,1.3l-2.4,0.4l-1.1,1.1l-0.7,0.7l0.8,1.7l0.6-0.6l0.7,1.5l2.7,1.5l-0.3,3v4.4H360l-1,1.5l-1.9,1.9l-2.3,1.7l0.1,2.3l1.1,1.1
            l0.3,1.4l1.6,1.6l2.4,0.4l0.3,1.6l0.8,0.8l1.5-1.3l0.8,0.8l1.5-1.5l-0.1,2.5l2,0.4v2l1.2,2.9l-0.7,1.8h-1l1,1.9l-0.8,2.4l-1.4,1.4
            l-0.5,1.6l-1.8-0.4l-0.1,2l1.2,1.2l0.4,2.3l-1.1,1.9l1.1,1.1v1.8l2.8,1.6l1.1,2.2l-1.7,1.6v1.3l0.9,1.5v2.3l-1.1,0.9l1.4,2.5h1.5
            l1.5,1.5l1.1,2.3l0.3,2.9l-1.3,1.3l0.6,2.7l2.9,0.6l1.5,1.5l1.5-1.5l0.5,3l-0.5,2.7l0.9,2l-1.7,1.7l-1.8,2.3l-1.3,2.4l1.9,2.7v3.2
            l-1.8,1.8l-0.2,2.9l-1.1,1.1l-3,3l-2.7-3.3h-1.8l-1.9,0.6l-1.9-3.2l-3.7-1.1l-1.2,1.2l-2.6-3.4l-1-2.7l-2.7,1.8l-2.3,1.3l-1,1.8
            h-4.4h-4.7l-2-2l-2.7-1.4l0.9-2.4l-3.4-1.4l-1.2,1.2l-2-2l-3.1-0.8l-2.2,0.5l-2.5-2.7h-2.2l-1.9-3h-2.2l-4.4,0.5l-0.9-2.5l0.6-2.5
            l-2.9-2.9v-2.2l-0.7-3.4l-3-3h-3.4l-1.4,1.4l-1.9-1.9l-1.8,1.8l-3.4-0.6l-1.9-2.2l2.2-2.2l-2.9-2.5l-2.9-1.5l-2.7,1.1l-2.7-1.1
            l-3.4,0.8l-2.5-2.5l-2.2-2.2l-1.2-1.2l2.3-2.3l1-3.1l-0.5-3.4l0.3-4.2l3.3,0.1v-2.8l4.1-2.3l4.1-0.9h4.3l0.9-2.8h2.4l2.3-2.3l3-1.5
            l-2-2l2.3-1v-1.6l-2.5-1.3L295.8,232.2z"
                        />
                        <path d="M350.4,182.5l-1,0.9l0.7,1l1.4-0.9l1.7,0.5l-0.5-1L350.4,182.5" />
                        <path d="M345.1,187.1l-0.6,1.5l0.9,0.8l1-1.3L345.1,187.1" />
                        <path
                            d="M332.8,179.7l-1.3,0.9l0.5,1l-0.9,1.3l0.2,0.8l2.1,0.6l2-0.9
            l1.2,0.1v-1.2l-1.8-0.5l0.9-1l-1-1.5l-1.3,0.5L332.8,179.7"
                        />
                    </g>
                    <g data-title="Хабаровский край" data-code="RU-KHA">
                        <path
                            d="M636.4,315.7l-1.4-3.3v-2.5l-1.7-1.7l1.5-1.5l0.4-3.3l-3.5-1.5
            h-2.8l-0.3-1.7l2-0.4l-1-2.1l0.6-3.9l-2.4-0.4l-2.4-2.5l2.1-0.5l0.2-3l-0.9-0.9l-0.6-1.6l0.8-2l-1.6-1.6l1.3-1.8l-1.1-1.1v-2.7
            l2.6-1.3l1.7-1l2.1,0.2l1.7-1.7l2.3-0.9l0.3-2.2l-1.5-1.5l0.8-2.1l2.9-0.6l3.9-3.9l-0.2-4.4l2.8-0.9v-1.4l-2.7-2.7l-0.6-1.9l-3-3
            l-1.9-0.4l-0.4-2.5l0.5-2.9l-1.5-1.5l2.3-3v-2.7l0.2-3.3l-1.2-1.2l-0.6-2.4l-1.1-1.1l-0.1-2.9l2.2-0.5l1.9,0.5l2.5,1.3l2.5-1.3
            l2-0.6l2-2l1.4-2.5l1.9,0.1l1.1-0.7l0.8,0.8l1.1-0.4v-2.1l-0.2-3.5l2,0.1l2.6-3l4.8,0.3l4.6,2.7l1.7,0.6v1.9l1.9,2.6L669,218v2.9
            l1.4,2.1l1.7-0.1l1.9-2.3l2.2,0.1l1.5,0.8l1.1-1h1.5l2.8,2.8l0.6,2h-1.5l-1.5,1.4l1,1.6l0.8,0.8l-2.2,1.4h-2.9l-2.9,2.8l-1.8,1.8
            l-2,3.4l-2.2,2.2l-2.2,4.3l-0.4,4.2l2,4.1l-1.9,1.9l-0.6,4.1l1.1,2.9l1.8,1.8l-1.5,2.5v3.4v4.6l-0.8,3.2v4.3l1.3,1.4v3v3.4l-1.1,4.3
            v3.5l-1.9,3.2v3.2l-1,2.8l1.1,2.6h1.9l1.5-1.5h2.1l1.6-1.6l1.8-1.8l1.3,1.3l1.5,0.8v1.6l1.2,2.2l1.5,1.5l1.3-1.3v-2l-1.8-0.8v-1.9
            l1.4-1.5v1.3l1.5,1.1l0.8-0.3l0.8,0.8v2.3v1.6l2.5-1v-1.3l1.4-1.9l0.9-0.8l1.4,1l0.3-2.1l-1.2-1.2l-1.1-2.2l-1.4-2.5l2.3-1.3
            l1.4-1.4l2.8-0.6h3.3l2.7,0.3l1.2,1.2c0,0,2.9-0.5,3.5-0.5c0.6,0,2.4-0.8,2.4-0.8l1.6,1.6l-1.2,1.2l-0.9,0.9l-0.8,1.4h2.2h2.3
            l0.5,1.6l1.9,1.9l2.3-0.3l1.2,1.2l1.3,2.4l0.4,4.8l1.6,5.1l2.6,3.7l3.2,3.2l1.3,1.3l0.6,2.2l2.5,2.5l1.8,3l0.6,1.8l2.3,3.6l2,2v5.3
            l-0.8,4.6l1.4,4.6l-2.1,1.8l-2.2-1.6l0.6-1.3l-0.8-1.6l-1.8,0.1l-1.4-1.4l-1.8-1.4l-1.5,0.2l-0.9,3.5l-2.5,2.1l0.4,1.7l2.3,0.3
            l0.6,1.8l2.1-0.2l0.8-1.6l1,0.3l1,2.4l-0.3,2.5l-0.8,1.8l-3,1.1l-0.4,2.1l1.5,0.4v3.6l-2,2.5l-2.6,0.4l-0.2,0.8l-2.6-0.4l-0.6,0.9
            l-1.7,0.1l-0.5-0.5l-1.8-0.5l-2.2,3l-0.3,2.4l1.4,1.4v1.1l-2.5,0.3l-0.6-2.8l-2.5-2.7l1.9-1.9v-2.8l-1.6-1.6l-1.8-1.8l-0.5-1.6
            l0.3-1.5l-0.8-0.8l0.2-2.1l-2.2,0.9l-2.6,0.3l-1.3,2.4l-3.5,0.8l-1.6,2.2l-2.5-3l-2.1-1l-1.9,0.8l-1,0.5l-2.2-0.4l-2.1,0.3l0.2,2.7
            c0,0-0.9,1.4-1.2,1.2c-0.3-0.3-1.2,2.2-1.2,2.2l-1.5-0.4l-0.1-3l-1.4-1.1l-1.1-0.3l-0.2-1.8l-2.4-1.7l-0.5-2l-2.8-0.3l-1.1,1.1
            l-1.4-2.2h-1.3l-1.4-1.4l-0.5-5.1l1.2-2.2l-1.5-2.3l1.7-2.1l0.1-1.7l2.2-2l0.3-3.7c0,0,2.3-2.3,2.3-2.5c0-0.3-0.1-1.7-0.1-1.7
            l-2.6-2.6l1-1.4l2.2,0.1l4.1-1.5l0.9-3.1l-2.3-1.6l-0.6-3.8l-2.2-2.2l-2.4,0.2l-1.4,2.4l-1.3,1.3l-2.5,0.5l-0.6,2l0.5,2.4l-0.8,0.8
            l-2.4,0.9l-1.2,1.7l-1.1,1.7c0,0-1.4-0.2-1.6-0.1c-0.2,0.2-1.5,1.4-1.5,1.4l-3.5-4.8l-2,0.6l-1.1,1l-3.1-0.4l-1.6-1.9l0.9-2.1
            l1.5-0.9v-1.7l-1.4-2.3l0.7-1.7l0.9,0.1v-3.9l1.6-2.2l-0.1-1.7l0.9-2.2l-2.3-1.3l-2.3,0.4l-1.8,1.8l-2,0.3l-1.4,1.8l-1.6,0.8
            L636.4,315.7z"
                        />
                        <path
                            d="M674.7,295.9l-1.1,1.2v1.5v1.6l1.9-0.8l1,1l1.1-1.1l0.4-2.2
            l-1.2-1.8L674.7,295.9"
                        />
                    </g>
                    <path
                        data-title="Краснодарский край"
                        data-code="RU-KDA"
                        d="M52.8,365.6l-1-1.3l-1.4-0.6l-1.4-2.2l-1-1l1.7-1.4l-0.4-2
        l1.4-0.4l-1-1l-3.4-3.6l-1.5,0.8l-1.3-1.3l0.1-1.3l0.4-1.6l-1.8-0.8l-1.1,1.1l-1.5-1.4l-1.4-1l-1.5-0.3l-0.5,1.6l1,1.9l-0.4,3.2
        l-1.9-0.6h-1.5l-2.9,0.1l-2.2,0.9l-0.9,1.9l-1.9-0.6l-2-2.5l-1.5,0.3l-1.4,1.4l0.6,2.5l-0.8,2.3l1,2l1.5,1.4l-0.3,3.8l1.3,1.9l1,2.2
        l0.5,4.3l-0.4,5.8l1,3l1.6-0.3l1.3,1.8l1,1.3l1.4,0.3l1.5-1.5l1.8-0.3l1.3-1.3h1.3l0.7,1.5l1.1,1.6h2.5l1.9-2.4l0.7-1.4l-0.5-1.8
        l1.3-0.4l2.1-1.6l-1.2-1.2l1-1.7l-0.8-1.5l-1-0.8l1-1.5v-1.5l1-1.1l1,0.3l0.7,1l1.8-0.2l1.1-1.1l-0.2-2.6L52.8,365.6z M39.6,372.5
        v2.8l-0.7,1.4l-0.5-1.7l-1.2,0.5l-1.1,1.1l-1.3,1.9l-1.4,1.4l-0.9,0.8v1l-1.3,0.2l-0.6,0.6l-0.7-1.3l-1.9-2.4l1.1-1.1l1,0.5h2.8
        l0.3-1l-0.5-1.2v-2.1h1.2l0.8-1.5l1.4,0.1l0.8-0.8l-0.7-1.7l-1.4-0.9l-0.4,0.5H33l-0.9-1l-1-1l-0.7-0.7l-0.6-1.1v-1.2l0.7-0.3
        l0.9,0.9l0.6,0.6l0.8,1.2l1.6,0.5l0.7-0.3l1.6,0.5l0.4,1.4l1.1,0.6l0.8,0.8l0.9,0.6L39.6,372.5L39.6,372.5z"
                    />
                    <path
                        data-title="Ставропольский край"
                        data-code="RU-STA"
                        d="M56,402.1l0.5-1l-1.4-1l-1-1l-1.8,0.4l-2.3-2.3l-0.5-1.5l-1.9-0.4
        l-2-0.6l0.6-1.4l-0.6-1.3l-1-1l0.6-1.1h2.2l-0.7-1l-0.3-1.6l-1.4-1v-2l-1.5-0.5l0.7-1.4l-0.5-1.8l1.3-0.4l2.1-1.6l-1.2-1.2l1-1.7
        l-0.8-1.5l-1-0.8l1-1.5v-1.5l1-1.1l1,0.3l0.7,1l1.8-0.2l1.1-1.1h1.4l0.9,1l1.4,0.7l0.6,1.7h1.3l1-0.4L59,374l1,1l2.3-0.4l1.4,0.6
        L64,377l1.2,1.4l0.4,1.6l1.2,1.2l0.8,2.3l-0.9,1.7l1,1.8l1.3,1.3l1,1.3v1.5l1.4,1.4l0.3,1.9l1.1,1.6v2.9l-2,1.3l-1.9,0.6l-1.6-0.6
        l-1,1l0.6,1l-0.9,0.8h-1.6v1.1l1.3,0.6l-0.8,2h-2.1l-1-1V405l-0.9,0.1l-1,1.3l-1.1,0.2l-0.5-2.2L56,402.1"
                    />
                    <path
                        data-title="Чеченская Республика"
                        className="rf-empty"
                        data-code="RU-CE"
                        d="M66.8,408.9l1.3,1.8l2.6,0.2l0.2,2.9l-1.3,0.4l-2.3,1.3l-2.2,2.7
        l-0.8,3l-2.1-1.2l-1.3,1l-2.1-0.2l-1.4,1.1l-2.2,0.3L56,420l-1.9-2l1.9-1.4l1.5-2.4l1.2-2.6l0.5-1.9l-2-1.6l-0.9-1.7l2.6,0.1
        l1.3-0.4l0.8-1l0.9-0.1v0.9l1,1h2.1L66.8,408.9"
                    />
                    <path
                        data-title="Кабардино-Балкарская Республика"
                        className="rf-empty"
                        data-code="RU-KB"
                        d="M39.7,397.4l1.5-0.6l1.5-1.5l1.2,0.1l1.8-0.7l2,0.6l1.9,0.4
        l0.5,1.5l2.3,2.3l1.8-0.4l1,1l1.4,1l-0.5,1l-1,0.8v1.5l-0.9,0.9l-2.3,0.1l-0.8,0.8l-1.1-1h-1.8L47,406l-1.1-0.2l-1.7,0.6l-1.8-1.6
        l0.5-2.5l-2.2-1.8L39.7,397.4"
                    />
                    <path
                        data-title="Волгоградская область"
                        data-code="RU-VGG"
                        d="M97.9,329.9l-0.2,2.2l0.8,3l2.8,0.4l1.7,0.9v1.5l2.6-0.1l3,2.9
        l0.4,3.5l-2.3,2.2l1.7,1.6l0.9-0.9l1.8,0.7l0.5,2.6l1.2,1.2h1.8l0.4,1.9l1.5,1.5l0.1,2.5l-0.9,2l-1.6,1.2h-2.5l-2,2l-1.6,1.6v2.3
        l-2.2,0.9l-2.3-0.1l-1.6,1.6l-0.9-2.6l-1.5-1.5l-1.8,1.2l-2.6-0.1l-1.3-1.3l-3,0.8l-0.9,2.3l-2.3-0.5l-1.3-1.3v-1.6l-1.2-0.4
        l-0.9,2.5l-1.3,0.5l-1.3-1.3l-1.5,0.6l-1.4-1.4l-2.3,2.1l-2.7-2.6l-0.6-2.3l0.8-2.1v-2.3l-2.8-2l1.6-1.6v-1.6l2.1-0.9l1.7,1.7
        l2.6-0.4l1.8-3.4l-1.1-4.5l3-3l1.1-2.7l-1.8-2.7l-0.2-1.7l3-3l0.9-3h3.6l1.8-1.6l1.9,0.4l0.4,1.4l1.9,1.1H97.9"
                    />
                    <path
                        data-title="Вологодская область"
                        className="rf-empty"
                        data-code="RU-VLG"
                        d="M124.2,249.7v-2.5l0.7-2.1l-1.8-0.6l-2,0.4l-2.6-1.2l0.9-0.9
        l0.3-1.9l-1.6-2.6l-1.8-0.7l1.3-2.7l1.4-1.4l2.4,0.4l0.8-1.1l1.6-0.4l1.6-2.1l0.7-1.7l1.1-2.2l2.9-1.6l1.9-2.7l1.4,0.8l2.3-1.3
        l2.1-0.7l2.1,0.4l1.8,0.8l2.1-0.4l1.6,1l0.1,1.1l1.8,2l-1.9,3.9l0.3,1.2l-1,1l0.7,3.5l1.5,0.9v1.6l2,1l0.5,1.3l0.8,0.5l1.2,1.2h1.3
        l1.1,1.1l0.5,1.4l2.5,0.1l1.9,0.5l0.9,0.9l1.6,0.4l0.6,1.6l1.5,1.5l1,0.7v1.3l1.1,0.4h2.2v-0.8l1,0.3h1.4v1.6l0.8,0.9h0.8v1l1.5,0.8
        l1,1l1.3-1.3l0.4-1.1l1.4,0.6l1,1.6l-1.1,1.1l1.5,0.8v0.8h1.1l1.4,0.6l-0.6,0.6l1.6,2l-2.8,2.8l-1.7,1.7l-2.4-1.1l-1.3,1.2l2.8,2.9
        l-1.8,0.9v2.3l-0.8,0.8l-2.9-2.6l-1.6,2.4l-2.8-0.7l-1.2,1.2l-1.7-2.5l-2.6-1.4l-2.1-1l-0.9-2.4l-2.5-2.5l-3.3-0.6l-1.7-3.6
        l-2.6,1.2l-2.8-1.2l-3.1,0.8l-2.8,1.2l-3-3v-2.7v-2.3l-2.4-2.4l-1.2,1.2l-3.2-2.2l-1.3-1.3l-2.3,0.2L124.2,249.7"
                    />
                    <path
                        data-title="Ростовская область"
                        data-code="RU-ROS"
                        d="M76.4,369.7v-2.7l-2.7-2.6l-0.6-2.3l0.8-2.1v-2.3l-2.8-2l1.6-1.6
        v-1.6l2.1-0.9l1.7,1.7l2.6-0.4l1.8-3.4l-1.1-4.5l3-3l1.1-2.7l-1.8-2.7l-0.2-1.7h-3.3l-1.1,1.1h-1.8l-0.9-1.2h-2.2V333l-1-1l-1,1.3
        l-0.9,2.2l-4.7,0.6l0.1,2l-1.8-0.3l-1.8,1.8l0.5,2.8l-1.9,1.1l-2.8,2.8l-3-3.3l-2-2.2l-2.2,0.9l-2.4-1l-2.8,1.3l-1.9,2l1.5,0.5
        L46,346l2.2,0.5l1.5,1.1l-0.5,2.3L47,349l-2.9-0.4l-0.4,1.6l-0.1,1.3l1.4,1.4l1.4-0.9l4.4,4.6l-1.4,0.4l0.4,2l-1.7,1.4l1,1l1.4,2.2
        l1.4,0.6l1,1.3l-1.2,1.2l0.2,2.6h1.4l0.9,1l1.6-1.3l1.2,0.9h2.3l0.9-0.9v-1.2h1.5l0.7,1.8v1.7l1.2,1.2l1.6,0.4l0.5,2.2l0.4,1.6l1,1
        l1.4,0.5l0.6-0.6l1.5,0.4l2.7-2.8H76l2.6-1.9l1.2-2.6v-1.9l-1.9,1L76.4,369.7"
                    />
                    <path
                        data-title="Астраханская область"
                        className="rf-empty"
                        data-code="RU-AST"
                        d="M101.9,369l-0.9-2.6l-1.5-1.5l-1.8,1.2l-2.6-0.1l-1.3-1.3l-3,0.8
        l-0.9,2.3l0.7,2.5l1.5,1l-0.8,4l0.2,2.4l1.2,1.6l2.8,0.1l-0.7,3.1l-1.5,0.8l-0.9,3.2l1.3,1.3l-0.8,2l-2.7-0.2l-0.2,1.6h-0.9l-0.2-1
        l-1.4-0.6l-1,1l0.9,1.2v1.5l0.8,0.8v1.2l-4.1,2.9l0.4,1l1.7,0l1.4,1l2.3,0.4l0.9,0.9l1.9,0.5c0,0-0.3,1,1,1s2.7-0.2,2.7-0.2h1.1h1.4
        h1.8l2.2-0.3l1.3-1.3l-1.6-1.6l-1.1-2.2l-0.3-2.3l1.9,0.3l1.3,1.4l1.3-1.3v-3.5l-0.7-4.3l0.6-3.3l-1.5-1.5l-2.4-2.3l-1.6-1l1.6-1.6
        l0.8-3.3l-1.8-1.8l-0.8-2.5L101.9,369z"
                    />
                    <path
                        data-title="Орловская область"
                        data-code="RU-ORL"
                        d="M68.4,284.7l-1.3,0l-1.4,1.4l0.7,1.1l1.3,0.5l1,0.8v2.1l1.5,0.4
        l0.4,1.2l1.3,0.6l0.4,2.8l1.4,2.3l0.4,2.9l1.4,0.3l1,1l1.5-1.2h1.6l1.1-1.1l-0.7-1.4l2.5-1.5l0.7-1.8l2-0.7l-0.9-2.8l0.9-0.9
        l-2.3-1.5l-1.6-3.2l-0.9-4.4l-2.8-1.1h-2l-1.8-0.6l-0.8,0.8l-0.4,1.6L72,283l-1-1h-1.4l-1.2,0.4l0.4,1.1L68.4,284.7"
                    />
                    <path
                        data-title="Липецкая область"
                        data-code="RU-LIP"
                        d="M94,294.4l-1.8,0.1l-0.6,1.1h-1.9l-0.7,1.5h-1.5l-1.3-1.8
        l-0.9-0.9l-2,0.7l-0.7,1.8l-2.5,1.5l0.7,1.4l-1.1,1.1H78l-1.5,1.2v2.2l1.6,0.3l1.8,1.1l2.2,0.4l1,1.9l2.3,0.8l-0.5,2.3l1.5,1.5
        l1.9,0.2l2,2.7l1.5-1.2h1.4l0.4-2l-1.4-1.4l0.3-1.8l-0.8-1l0.4-1.3l1.7-0.6l2.1-2.1l2.6-1.9l-0.5-3.4l-1.7-1.7l-1.6,1.1l-0.8-1.3
        l0.6-1.3L94,294.4"
                    />
                    <path
                        data-title="Тульская область"
                        data-code="RU-TUL"
                        d="M97.7,288.2l-1,1.7v1.5l-1.3,1l-1.4,2l-1.8,0.1l-0.6,1.1
        c0,0-1.9-0.4-1.9,0s-0.7,1.5-0.7,1.5h-1.5l-1.3-1.8l-0.9-0.9l-0.9-2.8l0.9-1.2l-2.3-1.2l-1.6-3.2l-0.9-4.4l1.8-2.4l1.1,0.4l1.1-1.1
        l2.2-0.6l1.3,0.7l1.5,1.5l1.7-0.3v-1.4l0.7-0.7l1.8,1.2l1.9-1.3l1.1,1.8l1.6,0.3v1.4l-0.5,1.6l0.5,1.6l-0.6,2.1L97.7,288.2z"
                    />
                    <path
                        data-title="Рязанская область"
                        data-code="RU-RYA"
                        d="M98.6,302l-0.5-3.4l-1.7-1.7l-1.6,1.1l-0.8-1.3l0.6-1.3l-0.6-1
        l1.4-2l1.3-1v-1.5l1-1.7l1.9-0.3l0.3-1.3l1.6-0.3l0.5,0.5l2.3-0.1l0.7-0.7l2,0.4l1.2,0.4l1.3-0.3l0.6-0.6l1.5,0.1h1.5l1.2,1
        l-0.5,1.5l1.9,0.6l0.3,1.7l1.3,0.4l1.6,2.6v2.3l1.2,0.8l0.8,0.8v1.2l0.4,1.2l-1.2,1.2l-1.5,0.4l-0.8,0.8l-1.2,1.2h-1.4v1l-1.1,1.1
        l-1.4,1l-1.2-0.6l-1.5-1.5l-1.2,0.1l-1.5-1.4l-1.4,1.6l-2.3-0.3l-0.8-2.1l-2.3-0.7L98.6,302"
                    />
                    <path
                        data-title="Тамбовская область"
                        data-code="RU-TAM"
                        d="M97.8,326.4l1.8-0.8l1.2-0.1l1.3-1.3l2.2-1.2l2.5-1.5l0.7-1.3h2.1
        l0.3-4l-1-1.9l0.5-3.3l-0.5-2l1.2-1.2l1.4-1.6l-1.5-1.5l-1.2,0.1l-1.5-1.4l-1.4,1.6l-2.3-0.3l-0.8-2.1l-2.3-0.7l-1.8,0.1l-2.6,1.9
        l-2.1,2.1l-1.7,0.6l-0.4,1.3l0.8,1l-0.3,1.8l1.4,1.4l-0.4,2h-1.4l-1.5,1.2l1.1,1.1l-0.6,1.9l1.5,1.5l2.4,1l0.8,1.6l1.5,1.5
        L97.8,326.4"
                    />
                    <path
                        data-title="Республика Мордовия"
                        data-code="RU-MO"
                        d="M121.4,300.2l1.4,1.4h2.3l0.6,1.8l1.2,1.2v2.4l1.6,1.6l0.6,2.1
        l2.1,0.6l1-1.5l3-0.1l0.6-0.6l2.1-0.1l-0.3,1.5l0.9,0.9h1.3l0.7,1.5l-1.3,1.9l0.6,1.3l-1.1,1.1l-0.4,1.8l-3.3,0.4l-1.2-1.3l-2,1.4
        l-1.8-0.2l-1.7-1.7l-1.5-1.5h-2.6l-0.7,0.7l-2.6-2.3V312l-2-2h-1.5l-2.2-0.9l-1.2-1.2l-1.4,0.5l-0.2-1.6l1.4-1l1.1-1.1v-1h1.4l2-2
        l1.5-0.4L121.4,300.2"
                    />
                    <path
                        data-title="Пензенская область"
                        data-code="RU-PNZ"
                        d="M111.5,306.3l1.2,0.6l0.2,1.6l1.4-0.5l1.2,1.2l2.2,0.9h1.5l2,2
        v2.4l2.6,2.3l0.7-0.7h2.6l3.2,3.2l1.8,0.2l1.2,1.9v3.1l-1.3,1.3v1.7l1,1l-0.5,1.4l-1,0.4l-1.2,2.2l-1.5,2.2l-1.5-1.8l-1.9,0.5h-1.6
        l-2.6-1.4l-2.9-1.2l-1.7-1.2l-0.6-2.2l-2.7,0.1l-1.9-1.8l-1.4-0.5l-1.1-1.1l-0.2-1.5l-1.9-1l0.7-1.3h2.1l0.3-4l-1-1.9l0.5-3.3
        l-0.5-2L111.5,306.3"
                    />
                    <path
                        data-title="Саратовская область"
                        data-code="RU-SAR"
                        d="M128.9,334.7l1,2l1.6,1.6h4.4l1.5,1.5l1.2,2.3v2.3l2.6,0.8
        l0.8,3.4l2.3,2.3l-0.5,2.7l1.8,1.8l0.9,1.4l-0.1,1.4l-3.1-0.6l-1.6,1.6l-2.9,1l-2.5-1.4h-2.7l-1.3,1.9h-2.7h-2.9l-0.6,1.4l-1.6,1.6
        l-0.3,2.4l-3.1-0.6l-1.8-1.8v-2.5l0.8-2.5l-1.3-1.3h-2.2l-0.1-2.5l-1.5-1.5l-0.4-1.9h-1.8l-1.2-1.2l-0.5-2.6l-1.8-0.7l-0.9,0.9
        l-1.7-1.6l2.3-2.2l-0.4-3.5l-3-2.9l-2.6,0.1v-1.5l-1.7-0.9l-2.8-0.4l-0.8-3l0.2-2.2l0.9-0.9l-1-2.6l1.8-0.8l1.2-0.1l1.3-1.3l2.2-1.2
        l2.5-1.5l1.9,1l0.2,1.5l1.1,1.1l1.4,0.5l1.9,1.8l2.7-0.1l0.6,2.2l1.7,1.2l2.9,1.2l2.6,1.4h1.6l1.9-0.5L128.9,334.7"
                    />
                    <path
                        data-title="Ульяновская область"
                        data-code="RU-ULY"
                        d="M140,316l3.3,2.3v2.2l2.6-0.6l1.9,0.9l1.9,1.9l1.8,1.6l1.9-0.8
        l1,1l0.7,3l1.8,1.8l0.6,1.6l-1.7,2.7l-2.4,1.1l-1.1,1.1l-1.9-2.6l-2.7-1.1l-1.2,1.2l-2.3-2.5l-1.6,1.6l-2.2-0.5l-1.7,1.7v2.9
        l1.1,1.1l-0.4,2l-2.3,0.3l-1.5-1.5h-4.4l-1.6-1.6l-1-2l1.5-2.2l1.2-2.2l1-0.4l0.5-1.4l-1-1v-1.7l1.3-1.3v-3.1l-1.2-1.9l2-1.4
        l1.2,1.3l3.3-0.4l0.4-1.8L140,316"
                    />
                    <path
                        data-title="Самарская область"
                        data-code="RU-SAM"
                        d="M167.9,339.2l-0.9-2.1l0.6-1.6l-3.1-2.7h-2l-1.5,1.5l-3.3-3.3
        l-1.7,2.7l-2.4,1.1l-1.1,1.1l-1.9-2.6l-2.7-1.1l-1.2,1.2l-2.3-2.5l-1.6,1.6l-2.2-0.5l-1.7,1.7v2.9l1.1,1.1l-0.4,2l-2.3,0.3l1.2,2.3
        v2.3l2.6,0.8l0.8,3.4l2.3,2.3l-0.5,2.7l1.8,1.8l0.9,1.4h4.5l1.3-2.6l2.4-1.7l1.4,0.4l0.8-1.7l3.5-0.6l1.7-4l2.9-1.6l2.2-2.2
        L167.9,339.2"
                    />
                    <path
                        data-title="Курганская область"
                        data-code="RU-KGN"
                        d="M232.2,348.2l3.3-2.2l2,0.9h1.7l1.9,0.8l1.3-0.5l1.7,2.9l3-2.3
        h2.7l1,1.3l-1.5,2.6l2.2,3.1l1.5,0.5v2.9l2,0.4l1.5,1.4h2.2l0.8,1.7l1.7,0.3l-0.4,2.4l5,3.1l0.4,3.8l-1.5,2.2h-3.2l-2.8,0.8
        l-2.3-1.3l-2.8,0.5h-3.4l-3.3-1.7h-1.3l-1.5,1.5l-2.5,0.5l-2.4-1.3H237l-2.9-0.9l-4.3,0.1l0.7-1.8l1.9-1.9l-1-2.5h-2.5l-0.3-1.2
        l-1.6-0.1l-1.5-1.5l0.5-1.7h1.7l1.2-1.2l-0.7-2.4l1.3-1.3h2.2l1.2-1.9l-0.4-3.2L232.2,348.2"
                    />
                    <path
                        data-title="Республика Башкортостан"
                        className="rf-empty"
                        data-code="RU-BA"
                        d="M215.4,340.4l-1.6,1.6l-1.5-2.2h-1.8l-2.9-2.1l-1.6-2.3l-1.4,0.6
        l-1.6-0.6l-1-3.4l-2.1-0.4l-1.1-2.3h-2.6l-2.6-2.1v-1.5l-1.6-0.6l-1.3,1.3l-4.9,1.4l-0.8,0.8l1.1,1.6v1.7l-2.6,2h-1.9l-1.5,0.7
        l-0.8-0.8l-1.3,0.7l0.4,1.7v1.1l0.8,0.8l-1,1l-1.6,1.6H175l-0.4,1.3l-0.9,1.4l-1.5,0.6l0.6,1.2l-0.1,3.3l1,1l-0.7,3.5l1.4,0.9
        l1.7,1.7l1.1,2.8v2.8l0.8,1.7l-0.7,1.7v2.4l-0.5,1.2l1.5,0.8l1.3-1.3h2.2l0.8,1.4l-0.8,1.9l-2.1,0.8l0.5,1l1.3,0.3l-0.4,2.5
        l-0.8,0.8l0.4,2h1.2l0.3,1.1l1.5,1.5l1.5-0.7l1.1-1.1l1.3,0.7l0.5,1.3l1.4,1l1.6,0.4l1.6-1.2l1.1-2.5l0.8-1.3l2-0.9l0.3-1.9l1.7-3.1
        h1.3v-2.2l1.7-2.2v-1.4l2.6-2.6l2-0.7v-0.9l2.1-0.2l0.9,1.2h1.5v-2.4l-0.9-2.8l-3.1-1l-1.3,0.9l-3.1-0.3l-1.6-2.6l-0.4-3.4l-1.2-1.2
        l-0.5-2.4l2.8-2.1l1.9,0.6l2.1,1l1.4,1.4v1.7l2.6,1l1.7-1.7l2.9-0.7v-1.5l1.5-2.8l0.6-1.9L215.4,340.4"
                    />
                    <path
                        data-title="Ярославская область"
                        data-code="RU-YAR"
                        d="M121.8,250.8l2.5-1.1l1.6-0.3l2.3-0.2l1.3,1.3l3.2,2.2l1.2-1.2
        l2.4,2.4v5l3,3v2.2l-3.3,0.1l-0.8,1.8h-1.7l-2.4,2.6h-1.6l-0.7,1.8l-2.6-0.6l-1.2-1.2h-2.9l-1.8,1.8h-2.1l-1.1,1.1l-1.5-1.5
        l-1.4-1.1l-0.2-1.3l-0.4-1.6l0.9-1.1l1.5-1.4l2.5-1.6l-0.9-2v-1.2l1.4-1.4l-0.6-2.2l0.7-1.6h1.8L121.8,250.8"
                    />
                    <path
                        data-title="Костромская область"
                        className="rf-empty"
                        data-code="RU-KOS"
                        d="M172.1,275.3l1.5-1.7l-2.9-2.6l-1.6,2.4l-2.8-0.7l-1.2,1.2
        l-1.7-2.5l-4.7-2.4l-0.9-2.4l-2.5-2.5l-3.3-0.6l-1.7-3.6l-2.6,1.2l-2.8-1.2l-3.1,0.8l-2.8,1.2v2.2l-3.3,0.1l-0.8,1.8h-1.7l-2.4,2.6
        h-1.6l-0.7,1.8l1.7,1.7h2.2l1.1,1.1l1.8-1.8l1,0.2l0.8,2.3l2.8,1.8h1.7v2.9l2.4,1.2v2.3l3,0.7l3.9-1l1.4-1.4h2.2l0.9,2.1l1.4,1.4
        h2.4l0.9,0.9l2-2l3.1-1.1l1.3,1.3l1.5-1.6h3.6L172.1,275.3"
                    />
                    <path
                        data-title="Ивановская область"
                        data-code="RU-IVA"
                        d="M120.4,270.3l1.8-1.8h2.9l1.2,1.2l2.6,0.6l1.7,1.7h2.2l1.1,1.1
        l1.8-1.8l1,0.2l0.8,2.3l2.8,1.8h1.7v2.9l2.4,1.2v2.3h-2.6l-1,2.6h-2.6l-1.8-1.8l-1.4,1.4l-0.7,2.7l-1.6,1.6l-3.3,0.4l-2.8,0.9l1.1-4
        l1.3-3.3l-3.3-3.3l-1.9-1.9l-3.2-1.1v-1.8l1.1-2L120.4,270.3"
                    />
                    <path
                        data-title="Владимирская область"
                        data-code="RU-VLA"
                        d="M117.2,271.4l1.1-1.1h2.1l1.3,2.1l-1.1,2v1.8l3.2,1.1l1.9,1.9
        l3.3,3.3l-1.3,3.3l-1.1,4l-2.6,0.4l-1.6,1.6l-2.1,0.4l-1.4,1.4l-1.6-2.6l-1.3-0.4l-0.3-1.7l-1.9-0.6l0.5-1.5l-1.2-1l0-1.8l0.8-0.4
        l0.4-1.3l0.2-2.4l-1-1l-1.2-0.5l-0.6-1.2v-1.6l-1.2-0.5l-0.6-1.7l1.4-1.1l-0.1-1.7l1-1l0.5-1.6l1.4-0.7l0.2,1.3l1.4,1.1L117.2,271.4
        "
                    />
                    <path
                        data-title="Нижегородская область"
                        data-code="RU-NIZ"
                        d="M118.9,296.1v-2.3l1.4-1.4l2.1-0.4l1.6-1.6l2.6-0.4l2.8-0.9
        l3.3-0.4l1.6-1.6l0.7-2.7l1.4-1.4l1.8,1.8h2.6l1-2.6h2.6l3,0.7l3.9-1l1.4-1.4h2.2l0.9,2.1l1.4,1.4h2.4l0.9,0.9l2.7,2l3.3,3.3l-2,2
        l-1.6,1.6l-2.7-1.6l-2.1,0.4l-1.5,1.5l-1.8,2.6l-3.4-2.1l-1.6,1.6l-2.6-0.1l0.2,2.8l-1.3,1.3l1.2,2.2l-3.7,2.1v3.1l-2.3,2l-1.1,1.8
        h-1.3l-0.9-0.9l0.3-1.5l-2.1,0.1l-0.6,0.6l-3,0.1l-1,1.5l-2.1-0.6l-0.6-2.1L127,307v-2.4l-1.2-1.2l-0.6-1.8h-2.3l-1.4-1.4L121,299
        v-1.2L118.9,296.1"
                    />
                    <path
                        data-title="Чувашская Республика"
                        className="rf-empty"
                        data-code="RU-CU"
                        d="M140.6,312.8l-0.7-1.5l1.1-1.8l2.3-2v-3.1l3.7-2.1l2.2,1.1h4.7
        l0.3,2.3l1.4,1.4l-0.7,2l1.4,2.7l-3.1,0.8l-2.9,1.7v3.1l-2.4,0.9l-2.2-0.9l-2.5,1L140,316l-0.6-1.3L140.6,312.8"
                    />
                    <path
                        data-title="Кировская область"
                        data-code="RU-KIR"
                        d="M154.6,296.5l1.8-2.6l1.5-1.5l2.1-0.4l2.7,1.6l3.6-3.6l-3.3-3.3
        l-2.7-2l2-2l3.1-1.1l1.3,1.3l1.5-1.6h3.6l0.4-6l1.5-1.7l0.8-0.8v-2.3l1.8-0.9l-2.8-2.9l1.3-1.2l2.4,1.1l4.5-4.5l1.4-1.2l1,0.3
        l2.4,2.9l-0.4,1.4l1.1,0.6l0.2,1.9l-1.6,1.6v2.6l-2.4,0.6v1.7l-1.7,1.7v1.9l1.3,1.3v1.9l0.6,2l-0.8,1.8l0.9,0.9l1.4-1.8l0.9-2.6
        l2.2-0.6l2.3,1.1l1.4,1.4l2-1.9l1.1,1.1l0.6,1.4l1.9-0.2l1.2-1.2h2.1l1.2,1.2l2.3,1.1l1.7,1.7l1.4,1.2l-0.3,2.2l-2.1,1.8l-2,0.8
        l-2.3,2.3v2.1l1.3,1.3l-0.7,2v2.1l-2.8,1.8h-2.2l-0.1-2.6l-2.6,0.1l-3-2.9l-2.3,1.7l-1.4,2.4l-0.7,2.2l-1.6,1.6h-2.2l-1.2-1.2
        l-2.3,2.4v3.7l-2.1,2.1l-1.6,1.6L174,318l-2.1-2.4l-0.7-3.1v-2l-0.7-1.9v-4l-2.1-1.2l-0.9-3l-2.8-0.6l-2.1-1.2l-0.9,1.6l-1.4-1.4
        L158,300l-2-1.3L154.6,296.5"
                    />
                    <path
                        data-title="Республика Марий Эл"
                        data-code="RU-ME"
                        d="M149.5,296l1.6-1.6l3.4,2.1l1.4,2.2l2,1.3l2.3-1.3l1.4,1.4
        l0.9-1.6l2.1,1.2l2.8,0.6l0.9,3l2.1,1.2v4l0.7,1.9l-2.2,1.2l-1.6-1.6h-2.8l-1.2-1.2l-2.9,1.7l-4.1,1.1l-1.4-2.7l0.7-2l-1.4-1.4
        l-0.3-2.3h-4.7l-2.2-1.1l-1.2-2.2l1.3-1.3l-0.2-2.8L149.5,296"
                    />
                    <path
                        data-title="Республика Татарстан"
                        className="rf-empty"
                        data-code="RU-TA"
                        d="M184.8,328.7l1.1,1.6v1.7l-2.6,2h-1.9l-1.5,0.7l-0.8-0.8l-1.3,0.7
        l0.4,1.7v1.1l0.8,0.8l-2.6,2.6H175l-1.4,2.7l-1.5,0.6l-1.5-1.4l1.5-1.5l-2.1-2.1h-2.1l-0.9-2.1l0.6-1.6l-3.1-2.7h-2l-1.5,1.5
        l-3.3-3.3l-0.6-1.6l-1.8-1.8l-0.7-3l-1-1l-1.9,0.8l-3.8-3.6l-1.9-0.9l-2.6,0.6v-2.2l2.5-1l2.2,0.9l2.4-0.9v-3.1l2.9-1.7l3.1-0.8
        l4.1-1.1l2.9-1.7l1.2,1.2h2.8l1.6,1.6l2.2-1.2v2l0.7,3.1l2.1,2.4l2.6,0.3l1.2,1.7l2.2,1l0.9,1.2l1.5,0l0.3,2.3l1.7,1.3l-1.7,1
        l0.1,1.1L184.8,328.7"
                    />
                    <path
                        data-title="Удмуртская Республика"
                        className="rf-empty"
                        data-code="RU-UD"
                        d="M175.1,315.1l3.7-3.7v-3.7l2.3-2.4l1.2,1.2h2.2l1.6-1.6l0.7-2.2
        l1.4-2.4l2.3-1.7l3,2.9l2.6-0.1l0.1,2.6h2.2l-0.6,2.8l-0.1,3.2l-1.6,2.8l-0.4,3.1l-1.4,3.2l-4.1,2.1v1.4l1.6,2.5l-1.3,1.3l-4.9,1.4
        l-0.8,0.8l-2-0.7l-0.1-1.1l1.7-1l-1.7-1.3l-0.3-2.3l-1.5,0L180,321l-2.2-1l-1.2-1.7L174,318L175.1,315.1"
                    />
                    <path
                        data-title="Республика Коми"
                        className="rf-empty"
                        data-code="RU-KO"
                        d="M296.1,228h-1.5l-2.5,2.2l-2.2,0.1l-1,2.4l-3.4-0.6l-0.9,1.5
        l-1.5,1.5l-1.5,0.8l-2.9,1l-2.4,0.6l-2.9-1.5l-1.5,0.3l-1.7-1.5l-3.4-1.3l-3.4-2.2l-2.5-1l-4.1-1.6l-2-2l-2.5-0.3l-4-2.6l-2-2
        l-3.1-1.4l-3-2.6l-2.8,0.3l-2.1,1.4l-13.2,0.1l-1.4,1.4l-0.7,1.9l-0.9,0.9v0.9l-0.9,0.9l-1.1,1.1l0.5,1.1l1.4,1.4l0.6,1.2l-0.8,1.7
        l0.4,1.7l1,1l-1.1,2l-1.1,1.1l-0.8-0.8h-1.1l-1.6-1.3l-1.6-0.4l-1.3-0.8l-1.7,0.6l-1.5,0.8l-0.4-2l-2-2l-1.8-1.1l-2.2-0.4l-1.1-1.1
        l-1.5-0.5l-1.8-1.3h-1.6l0.3,1.7v1.5l1.3,1.9l1.3,1.3l-0.5,1.8v1.8l-1.7,1.1l-0.5,1.9l0.9,0.9l0.3,2l-0.3,1.2l-1.8-0.4l-1.5,0.4
        l-0.5,1.2l-0.6,0.6l0.3,1.4l-1.5,0.8l0.2,2.2l1.3,0.6l1.2,0.4l1.1,1.1l1.1-1.6l0.8,0.3l0.7-1.6l1.6-0.3h1.1l0.8,0.8l1.3-0.6l0.1,1.7
        l1.1,1.1l-1.6,1.6l-1.1,0.5l-1.7,1.7l-2,1.2l-0.2,1.1l-1.5,1l-1,1l1.4,1.4l-1.1,1.8l-1.3,1l-1.1,1.3l-1.3-0.3l-0.3-1h-2l-1.1-0.3
        l0.1,0.9l-1.6,0.4l0.2,1.9l-1.6,1.6v2.6l-2.4,0.6v1.7l-1.7,1.7v1.9l1.3,1.3v1.9l0.6,2l-0.8,1.8l0.9,0.9l1.4-1.8l0.9-2.6l2.2-0.6
        l2.3,1.1l1.4,1.4l2-1.9l1.1,1.1l0.6,1.4l1.9-0.2l1.2-1.2h2.1l1.9-0.8v-1.5l-1.3-1.3l1.2-2.3l2.6,1.4l0.9-0.9l0.9,0.9l1.2-1.2
        l1.6,0.6l0.7,1l-0.3,1.6l1.3,1.6l1.6-0.6l2.8,1.2l1.8,1.8l2-1l2.3,0.9l1.3-1.3l1.3,0.4l0.9,0.9l1.3-0.4l0.8-1.6h1.3l2.9,2l2.9,1.5
        l3.2,1.2l4.4,0.7l0.7-1.8l1.2-1.2l0.4-2.2l3.1-3.1v-1.7l1-2l-0.5-2.2l1.7-3.2l2.6-1.6l0.3-2.3l3-1.9l-0.5-2.8l1.1-2.2l2.6-2.6
        l1.9-1.9l1.6,0.6v2l2.2-0.3l2.4-2.4l2.4-1.2l1.3-1.5l2.4-0.4l3.3,0.1v-2.8l4.1-2.3l4.1-0.9h4.3l0.9-2.8h2.4l2.3-2.3l3-1.5l-2-2
        l2.3-1v-1.6l-2.5-1.3l0.4-2.3l1-0.5l0.6-1.4L296.1,228"
                    />
                    <path
                        data-title="Пермский край"
                        data-code="RU-PER"
                        d="M201.2,302.2l-2.8,1.8l-0.6,2.8l-0.1,3.2l-1.6,2.8l-0.4,3.1
        l-1.4,3.2l-4.1,2.1v1.4l1.6,2.5l1.6,0.6v1.5l2.6,2.1h2.6l1.1,2.3l2.1,0.4l1,3.4l1.6,0.6l1.4-0.6l1.7-1.8l1-1v-2.2l0.9-1.7l0.8,0.8
        h2.5l2.1-1l0.6-2.4l1.3-2.2h1.7l1.5,2.3l1-1l1.4-1.4l-1-1l-0.1-1.2l2.3-1.5l2.2,0.5l1.6-1.3l1.6-1.6l-1.4-1.4v-0.9l1.3-1.3l0.9-2.9
        l-1.6-2.6v-3.2l2.1-1.8h2.2l1.7-1.7l1.9-2.9l2.6-2.3l1.4-3.5v-2.6l2.6-1.3v-2l-4.4-0.7l-3.2-1.2l-2.9-1.5l-2.9-2h-1.3l-0.8,1.6
        l-1.3,0.4l-0.9-0.9l-1.3-0.4l-1.3,1.3l-2.3-0.9l-2,1l-1.8-1.8l-2.8-1.2l-1.6,0.6l-1.3-1.6l0.3-1.6l-0.7-1l-1.6-0.6L207,278l-0.9-0.9
        l-0.9,0.9l-2.6-1.4l-1.2,2.3l1.3,1.3v1.5l-1.9,0.8l1.2,1.2l2.3,1.1l1.7,1.7l1.4,1.2l-0.3,2.2l-2.1,1.8l-2,0.8l-2.3,2.3v2.1l1.3,1.3
        l-0.7,2V302.2"
                    />
                    <path
                        data-title="Республика Бурятия"
                        data-code="RU-BU"
                        d="M518.7,433.8l-4.4-0.6l-1.8-1.1l-5.8,0.8H503l-3,3l-3.3,0.8
        l-3.2,0.9l-4.6-0.4l-3.1-1.3l-2-2l-1.8-3.9l-2.3-2.3l-3.3-1.3h-3.2l-3.6,0.9l-2.5-2.5h-4.9l-2.9-2.2l-1.5,1.5l-0.2-2.4l-1.2-1.2
        l-0.5-2.2l1.4-1.4v-2.2l1.5-1.5l3.3-3.3l0.9-0.9l1.1-1.1l1.8,1.1l-0.4,1.7l2.4,1.1l1.4,1.3l3.1,1l1.4,1.4l1.6,0.9l1.8,1.8l4.1-0.9
        l1.5,1.6l1.9,1.6h2v1.8l1.4,2.3h2.1h1.5l0.7,2.3l2.9-1.6h2.1v-3.7l2.9-2.9l3.4-4.1l1.7-4.4l4.5-4.5l5.1-6.1l0.8-9l1.8-6l-2.8-0.9
        l-1.4-6.8l-1.2-5.6l1-1.8l1.3-1.3l-2.2-1.5l-2.1-0.7l-0.8-3.4l4-2.9h2V357l1.9-0.9l2.8,1l2.9-1v-2.5l2-1.4l1,1.1h3.2l1.9-1.3
        l1.2,1.2l3.9,0.8l1.6-1.6l1.7,1l2.5-2.5h1.9v-5l2.3-1.1l1.4-2.9l2.4,0.6l1.1,1.1l-2.8,2.6v3.4l2.4,1.2l1,3l1.5,1.8l1,3.2l2.2,2.4
        l2-0.8l2.8,1.4l1.9,1.9l0.8,2l-1.1,3.9l-2.9,0.9l-2.4,2.4l-1.8,2.9l-1.3,2.9l-2.1,2.1l-1.6,3.1l1,3.1l2,0.6v1.5l1.5,1.5l-0.9,3.1
        l-4.8,4.8l-2.8,1.3l-1.7,1.7l-2.2,0.9l-0.6,3.6l-1.7,1.7l-1.9,0.8l-1.2,4.3h-2l-2.5-0.4l-3.3,3.3l-1.7,0.9l-1.4-0.6h-1.4l-1.4,1.4
        v2.4l1,1.2l-1.6,0.7v4.5l2.2-0.3l1.5,1.5l-4.1,4.1l0.5,2.4H518.7"
                    />
                    <path
                        data-title="Забайкальский край"
                        className="rf-empty"
                        data-code="RU-ZAB"
                        d="M597.7,392.1l0.8-5.4l1-2.2l-1-4.1l-1.8-1.8l-1.6-1.6l-2,1.6
        l-1.4-1.8l1-2.5l1.4-4.2l2-2l4-2.3l-1.8-3.2l-0.8-1.6h-2.2l-0.6-2.7l-1.1-1.6l1.9-1.1v-0.9h-1l-1.7-1.8l-1.4-0.2l0.3-3.2l-0.8-1.5
        l-0.9-2.5l-2-1l-1.5,2l-1.5-0.6l-0.1-2.2l-1-1l-1.5-0.4l-1.6,1.6l-0.8,1.9l-2.6-0.7l-0.8-1.6l1.5-1.9l-0.9-1.5l-1.7,0.6l-2.2-0.6
        l-1.5-1.5l0-1.5l-2.1-0.6v-2.2l-1.9-0.6l-2-2.6l-2.8-1l-0.6-2.9l-1.3-1.3l-1.6,1.2h-1.6l-1.2-1.2l-2-0.1l-2.2,2.2l0.9,0.9l-1,2.2
        l1.1,1.1l-1,2.6l3.7,3.7l2-0.9v1.6l0.9,0.9l-0.5,2.5l-2,1.2l-1.1,2.2l-2.9-0.8l-1.9,1l-2.3-0.2v3.4l2.4,1.2l1,3l1.5,1.8l1,3.2
        l2.2,2.4l2-0.8l2.8,1.4l1.9,1.9l0.8,2l-1.1,3.9l-2.9,0.9l-2.4,2.4l-1.8,2.9l-1.3,2.9l-2.1,2.1l-1.6,3.1l1,3.1l2,0.6v1.5l1.5,1.5
        l-0.9,3.1l-2.9,2.8l-1.9,1.9l-2.8,1.3l-1.7,1.7l-2.2,0.9l-0.6,3.6l-1.7,1.7l-1.9,0.8l-0.7,2.4l-0.6,1.9h-2l-2.5-0.4l-3.3,3.3
        l-1.7,0.9l-1.4-0.6h-1.4l-1.4,1.4v2.4l1,1.2l-1.6,0.7v4.5l2.2-0.3l1.5,1.5l-4.1,4.1l0.5,2.4l1.8,1.8l1,1.9l3.4,1.1l3.8-0.5h5.2
        l3.7-1.1h4.1l1.6-1.6l3.1-3.1l4.1-1.9l2.9-2l2.2-2.2l1.1-3.7l4.6-4.6l3.2-1.4l2.4,0.9l2.7,1.4l3.5-2l2.5-1.5l3.4,0.6l4.7,0.4
        l4.2-1.5l2.5-2.5l1.7-4l2.7-0.8v-3.5l-1.1-1.1l-1.9-3.7l1.4-3.5V392.1"
                    />
                    <path
                        data-title="Иркутская область"
                        data-code="RU-IRK"
                        d="M558,320.9l-1-2.8l-1.6-0.2V316l-1.7-1.9l-1.4-1.4h-2v1.9
        l-1.8,1.8l-2.8-0.4v-1.8l-1.2-1V311l-2.5-0.3l-2.8-1.3l-2.7,0.3l-2.7-0.4l-1.5,1.8l-0.8,2.5l-1.8,1.8l0.7,2.5l-0.9,2.8l-2.4,2.4v4.4
        l-3.3,2.3v2.6l-1.4-2v-2.2l-1.7-1.7l-1.6,0.7l-0.4,2.1l-4.2,1.6h-2l-0.8,2.4l-1.3,1.9l-2.7,0.2l-3-3l-0.3-6.3l0.4-3.8v-3.6l0.5-3.2
        v-3.1l-2.2-0.9l-1.6-0.6l-0.2-1.5l-0.7-1.7l-1.4-1.4v-1.7l1-1l0.3-2.3l-1.4-1.4h-1.8l-2-0.9V297l0.8-0.8l-0.9-0.9l-0.7-2.8l-1.6-2.8
        l-1.9-1.3l-2.1,0.6l-2.1,0.2v-2.5l1.7-0.5v-1.6l-1.8-1.8l-0.7,1.4l-1.1,1.1l-1.5-0.3l-1.7,1.7l-1.7,0.6l-1,2.3v2.3l0.9,2.5l-0.7,2.1
        v1.8l2.2,2.2l1.4,1.4l-0.6,1.8l-0.9,1.5l1.6,1.6l-1.6,1.6l-1.1,0.8v3.4l-1.3,0.8l-0.5,2.3l-2.2,0.9l0.6,3l-1,0.9l-0.4,2.8l0.6,2.5
        l1.3,0.6l2.1,2.1l0.9-0.9l2,0.8v2.2l0.7,1.9l-1.4-0.2l-1,1.7l-0.8,1.5l0.4,1.2h2l1,2l0.7,1.8l-1.1,1.1l-1.2,1.9v1.6l-0.6,1.4h-1.9
        l-1.6-1.6h-2.2l-0.8-1.5l-2-0.9l-0.4-1.3l-1.6-0.4l-2.2,1.4l-0.6,2.5l1.2,2.6l-1.7,2.5l-2.7,2.7l-1.9,2.7l-0.6,2.7l-1,2.3l1.8,1.8
        l-1.7,1.2l-2.5-1.7l-1.2-2.9l-2.1-2.1l-1.9,1l-0.9,2.7l-3.2,1.2l-2.5-0.4l-2.7,0.6l-0.1,3.8l-1.5,1.5v3.6l1.8,1.8l1.2,2.9v1.7
        l-1.9,1l-0.9,2.3h-1.8l-0.5,2.4l-0.9,2l1.4,2l0.1,2l-1,0.7v3l0.5,1.7l-0.9,1.6l0.3,2l-3.7,0.2v1.6l-1.6-0.3l-0.6,1.6l2.4,1.5
        l3.6,3.6l1.7,1h2.3l1.9,1.4l2.9,0.4l2.4,2.3l1.6,0.8h2.8l1.1-1.1l2.4,2.4l5.4-5.4l2,1.4l-0.6,1.4l2.4,1.1l1.4,1.3l3.1,1l1.4,1.4
        l1.6,0.9l1.8,1.8l4.1-0.9l3.4,3.2h2v1.8l1.4,2.3h3.6l0.7,2.3l2.9-1.6h2.1v-3.7l2.9-2.9l3.4-4.1l1.7-4.4l4.5-4.5l5.1-6.1l0.8-9l1.8-6
        l-2.8-0.9l-2.7-12.4l1-1.8l1.3-1.3l-2.2-1.5l-2.1-0.7l-0.8-3.4l4-2.9h2V357l1.9-0.9l2.8,1l2.9-1v-2.5l2-1.4l1,1.1h3.2l1.9-1.3
        l1.2,1.2l3.9,0.8l1.6-1.6l1.7,1l2.5-2.5h1.9v-5l2.3-1.1l1.4-2.9l2.4,0.6l1.1,1.1l-2.8,2.6l2.3,0.2l1.9-1l2.9,0.8l1.1-2.2l2-1.2
        l0.5-2.5l-0.9-0.9v-1.6l-2,0.9l-3.7-3.7l1-2.6l-1.1-1.1l1-2.2l-0.9-0.9l2.2-2.2l2,0.1l1.2,1.2h1.6l1.6-1.2l0.4-1.4L558,320.9"
                    />
                    <path
                        data-title="Тюменская область"
                        data-code="RU-TYU"
                        d="M275.8,375.2l-1.1-2.3h-3.2l-2.5-1.5l-2.8-0.3l-0.4-3.8l-5-3.1
        l0.4-2.4l-1.7-0.3l-0.8-1.7h-2.2l-1.5-1.4l-2-0.4v-2.9l-1.5-0.5l-2.2-3.1l1.5-2.6l1.6-1.2l-0.7-2.3l1-1l-0.5-2.8l2.2-2.2l1.9,0.5
        l1.5-1.5l1.7,0.7l2.9-1.5v-2.1l-0.6-1.7l0.7-2.4l1.8,1.4h2.7l2-0.6v-1.7l1-1l3.5,1l1.4-1.4h3.8l1.1-1.1l2-0.7l2.1-0.8l1-1l-0.1-2
        h1.4l1.7,1.4l1.5,0.8l1.7,1.7h1.9l2.5,0.7l2,2l1.4,2.6l1.7,2.8v2.3l2.6,0.4v2.2l1.3,1.3l2.1,0.7l1.2,1.2h2.3l1.6,1.4h2.1l-1.1,1.6
        l-2,2l-2.5,1.2h-3l-1.9-0.6l-2.2-1.1l-1.3,1.2h-2.1l-1.6-1l-1.4-0.7l-2.2-0.7l-1.7-0.6v-1.5l1.2-1.2l-2-2l-1.9,1.3l-1.1,1.9
        l-1.5,1.5l-0.2,2l0.9,2.6l-2,1v1.4l1.7,0.9l1.2,1.2l0.6,1.5l1.9,1v1.7l-0.9,0.9l-2.4,0.4l-1.4,1.4l-1.8,1.8v2.2l-0.5,1.3l-1.3,1.3
        l-1,0.2v1.7l-1.3,1.3l-0.7,1.5v1.5H275.8"
                    />
                    <path
                        data-title="Свердловская область"
                        data-code="RU-SVE"
                        d="M249.8,347.8h-2.7l-3,2.3l-1.7-2.9l-1.3,0.5l-1.9-0.8h-1.7l-2-0.9
        l-3.3,2.2l-1.3-1.3l-1.4-1.4l-1.8-1l-1.6,1.6l-1.7-1.7l-1.7-0.2l-0.4-1l-2.3-0.8l-0.7-1.3l-2.1-1.4l-1.7,0.7l-1.6,1.6l-1.5-2.2h-1.8
        l-2.9-2.1l-1.6-2.3l1.7-1.8l1-1v-2.2l0.9-1.7l0.8,0.8h2.5l2.1-1l0.6-2.4l1.3-2.2h1.7l1.5,2.3l1-1l1.4-1.4l-1-1l-0.1-1.2l2.3-1.5
        l2.2,0.5l1.6-1.3l1.6-1.6l-1.4-1.4v-0.9l1.3-1.3l0.9-2.9l-1.6-2.6v-3.2l2.1-1.8h2.2l1.7-1.7l1.9-2.9l2.6-2.3l1.4-3.5v-2.6l2.6-1.3
        v-2l0.7-1.8l1.2-1.2l1.8,1.1v1.6l1,1l1.1,0.8l1.7,1.7l3,3l1.9,3.4l1.5,1.5v3.4l-1.3,1.7l0.7,3.9l-0.8,3.2l1.6,1.6v2.5l-1.5,1.5
        l0.5,2.9l2.1,1.1l1.5,1.8l0.1,2h1.5l1.5,1.5l-0.8,1.5v2.9l1.4,1.4l-0.7,2.4l0.6,1.7v2.1l-2.9,1.5l-1.7-0.7l-1.5,1.5l-1.9-0.5
        l-2.2,2.2l0.5,2.8l-1,1l0.7,2.3l-1.6,1.2L249.8,347.8"
                    />
                    <path
                        data-title="Ханты-Мансийский автономный округ - Югра"
                        className="rf-empty"
                        data-code="RU-KHM"
                        d="M273.4,251.3l-0.3,4.2l0.5,3.4l-1,3.1l-2.3,2.3l1.2,1.2l2.2,2.2
        l2.5,2.5l3.4-0.8l2.7,1.1l2.7-1.1l2.9,1.5l2.9,2.5l-2.2,2.2l1.9,2.2l3.4,0.6l1.8-1.8l1.9,1.9l1.4-1.4h3.4l3,3l0.7,3.4v2.2l2.9,2.9
        l-0.6,2.5l0.9,2.5l4.4-0.5h2.2l1.9,3h2.2l2.5,2.7l2.2-0.5l3.1,0.8l2,2l1.2-1.2l3.4,1.4l-0.9,2.4l2.7,1.4l2,2h4.7h4.4l1-1.8l2.3-1.3
        l2.7-1.8l1,2.7l2.6,3.4l1.2-1.2l3.7,1.1l1.9,3.2l1.9-0.6h1.8l2.7,3.3l0,3.9l3.2,2.1l3.6,1.6l-1.9,3.1l-4.5,2l-2.9,2.9l-4.7-3.4
        l-5,2.1l-0.9,3.1l-3.4-1.7l-2.4,0.6l-2.9-2.9l-3.2,1.2l-4.1-0.5l-1.1-1.6h-4.4l-3-1.3l-4.1,0.6l-1.5,3l-1.5,3.7l0.2,4l-4.2,1.2
        l-3.4,2.5l-0.4,2.7l-3.6,2.1h-2.1l-1.6-1.4h-2.3l-1.2-1.2l-2.1-0.7l-1.3-1.3v-2.2l-2.6-0.4v-2.3l-1.7-2.8l-1.4-2.6l-2-2l-2.5-0.7
        h-1.9l-1.7-1.7l-3.2-2.2h-1.4l0.1,2l-1,1l-4.1,1.5l-1.1,1.1H275l-1.4,1.4l-3.5-1l-1,1v1.7l-2,0.6h-2.7l-1.8-1.4l-1.4-1.4v-2.9
        l0.8-1.5l-1.5-1.5h-1.5l-0.1-2l-1.5-1.8l-2.1-1.1l-0.5-2.9l1.5-1.5c0,0,0.4-2.1,0-2.5s-1.6-1.6-1.6-1.6l0.7-2.6l-0.5-4.5
        c0,0,0.8-1.2,1.3-1.7c0.5-0.4,0-3.4,0-3.4l-1.5-1.5l-1.9-3.4l-3-3l-1.7-1.7l-2.1-1.8v-1.6l-1.8-1.1l0.4-2.2l3.1-3.1v-1.7l1-2
        l-0.5-2.2l1.7-3.2l2.6-1.6l0.3-2.3l3-1.9l-0.5-2.8l1.1-2.2l2.6-2.6l1.9-1.9l1.6,0.6v2l2.2-0.3l2.4-2.4l2.4-1.2l1.3-1.5L273.4,251.3z
        "
                    />
                    <g data-title="Красноярский край" data-code="RU-KYA">
                        <path
                            d="M417.6,108.5l-1,1.3l-0.9,1.7l-0.8-0.3l0.6-2.2v-2.3l-2.5,0.8
            c0,0-1.5,0.3-1.8,0.5c-0.3,0.1-2.1,0.7-2.1,0.7l-0.8,1.7l-0.5,1.5l-1,1.3l0.3,1.5l-2,1.5l0.9,0.8l1.4-0.5l1.2,1.6l0.1,1.5l1,1.3
            l2.6,0.2l0.3,1.5l1.8-1.1l0.9,1.5l2.5,0.9l2.4-1.2l1.2-1.6l-0.9-1.8l-1.5-2.1l1.2-0.8l0.5-2l-0.6-2.2l0.2-2.2L417.6,108.5z"
                        />
                        <path
                            d="M384.4,174.7l-1.4,0.7c0,0,0.6,1.7,0.6,1.5c0-0.2,1-0.5,1-0.5
            L384.4,174.7z"
                        />
                        <path
                            d="M462.6,227.2l0.7,1.7l1,2.2l2.2,1.5l0.2,4.2l2.2,4v1.7l0.6,3.2
            l0.7,4.9l-1.9,0.6l-1.3,2.1l-1.2,1.2l0.7,2.1l0.7-0.7l2.4,1v2.1l0.9,0.9l-0.9,0.8v2.2l2.2,1.2l0.7,3.5l2.6,2.6l-0.9,2.5l-1.5,2.4
            l0.6,1.9v1.7l-0.8,0.8v2.5l1.3,0.6h2.1l1-1h1.2l1.1,1.3l2.3-0.9l1.7,0.8l1.4-1.4l1.9,0.1l0.1,1.5l-0.7,1.4l-1.1,1.1l-1.5-0.3
            l-1.7,1.7l-1.7,0.6l-1,2.3v2.3l0.9,2.5l-0.7,2.1v1.8l2.2,2.2l1.4,1.4l-0.6,1.8l-0.9,1.5l1.6,1.6l-1.6,1.6l-1.1,0.8v3.4l-1.3,0.8
            l-0.5,2.3l-2.2,0.9l0.6,3l-1,1l-0.4,2.8l0.6,2.5l1.3,0.6l2.1,2.1l0.9-0.9l2,0.8v2.2l0.7,1.9l-1.4-0.2l-1,1.7l-0.8,1.5l0.4,1.2h2l1,2
            l0.7,1.8l-1.1,1.1l-1.2,1.9v1.6l-0.6,1.4h-1.9l-1.6-1.6h-2.2l-0.8-1.5l-2-0.9l-0.4-1.3l-1.6-0.4l-2.2,1.4l-0.6,2.5l1.2,2.6l-1.7,2.5
            l-2.7,2.7l-1.9,2.7l-0.6,2.7l-1,2.3l1.8,1.8l-1.7,1.2l-2.5-1.7l-1.2-2.9l-2.1-2.1l-1.9,1l-0.9,2.7l-3.2,1.2l-2.5-0.4l-2.7,0.6
            l-0.1,3.8l-1.5,1.5v3.6l1.8,1.8l1.2,2.9v1.7l-1.9,1l-0.9,2.3h-1.8l-0.5,2.4l-0.9,2l1.4,2l0.1,2l-1,0.7v3l0.5,1.7l-0.9,1.6l0.3,2
            l-3.7,0.2v1.6l-1.6-0.3l-0.6,1.6l2.4,1.5l3.6,3.6l1.7,1l-0.7,1.6l-1.4-0.5l-1.1,1.1l-2.8-0.1l-2.4,2.4l-3.3-0.6l-1.2,1.2l-0.8,4.6
            l-2.9,0.8v2.1l-1.9,1.9v3.2l-2.7,0.8l-3.7,3.2l-2.7,1.1h-2l-2.6-1.3h-2.9l-2.9-1l-2.2-2.2l0.9-1.3l1.1-2.8l2.3-1l2.1-2.1l-0.3-2.6
            l2.1-2.1l0.8-2.6l-0.7-2.1v-2.4l-2.7-2.7l-0.8-3l-2.6-2.6l0.8-3.4l-0.8-1.6l-2.4,0.8l-3.7-0.4l-1.2,1.2l-2.5-0.5l-2.4-2.6l-1.4-2.4
            l2.3-2.3l1.5-0.6l-0.2-3.6l-1.3-2.6l-1.8-0.6l-1-0.9l-0.4-2l0.6-2.7v-3.8l1.6-2.9l2.8-1.4L394,363l-3.4-1.1h-2.2l-0.6-2l-1.9-3
            l1.2-3.2l4.1-4.4l-1.3-1.3l-3.7,0.3l-0.8-2.9l-2.4-2.4l-1.5-2.3l-3-0.8h-4.1l-3.4,0.9l-2.8-0.9l-0.8-2.5l2.3-2.3l-2.5-5.1l2.9-2.9
            l4.5-2l1.9-3.1l-3.6-1.6l-3.2-2.1l0-3.9l3-3l1.1-1.1l0.2-2.9l1.8-1.8v-3.2l-1.9-2.7l1.3-2.4l1.8-2.3l1.7-1.7l-0.9-2l0.5-2.7l-0.5-3
            l-1.5,1.5l-1.5-1.5l-2.9-0.6l-0.6-2.7l1.3-1.3l-0.3-2.9l-1.1-2.3l-1.5-1.5h-1.5l-1.4-2.5l1.1-0.9v-2.3l-0.9-1.5v-1.3l1.7-1.6
            l-1.1-2.2l-2.8-1.6v-1.8l-1.1-1.1l1.1-1.9l-0.4-2.3l-1.2-1.2l0.1-2l1.8,0.4l0.5-1.6l1.4-1.4l0.8-2.4l-1-1.9h1l0.7-1.8l-1.2-2.9v-2
            l-2-0.4l0.1-2.5c0,0-1.3,1.3-1.5,1.5c-0.2,0.2-0.8-0.8-0.8-0.8l-1.5,1.3l-0.8-0.8l-0.3-1.6l-2.4-0.4l-1.6-1.6l-0.3-1.4l-1.1-1.1
            l-0.1-2.3l2.3-1.7l1.9-1.9l1-1.5h2.5v-4.4l0.3-3l-2.7-1.5l-0.8-1.6l-0.5,0.7l-0.8-1.7l1.7-1.7l2.4-0.4l-0.3-1.3l1.2-1.2l-0.4-1.6
            l-1.8-1l-1.6-0.3l-1.1-1.1l-0.5-1.4h-1.2l-0.1-1.5l2.5,0.5l2.1-0.3l1.7,1.7l3.3,1.8l-0.9,2.5l0.8,0.8l2.2,2.2h3.4l0.5-2l-1.4-1.9
            l0.1-1.8l-2.4-0.9l-1.8-2.9l-1-1l0.8-3l1.1-2.5l-1-1l-0.6-3.2l2.5-2.3h6l3,1.1v-0.8h4.9l3.3-1.4h2.4l1.3-1.3l-1.3-1.9l-1.5-0.5
            l-1.3-1.3c0,0,0.5-0.5,1.5-1.5c1-1,1,1,1,1l1.5-0.4l-1-1.1l0.7-1.6l-1.1-1.1l1.3-1.8l1.8-1.4l-1.8-0.1l-0.1-1.9l3.5,0.1l1.4-2.9
            l3.5-1.3l1.3-1.3l4.8-0.7l1.2-1.2l3.4-1l0.8-0.9l-2.3-0.8l1.3-1.3l3.3-0.8l2.8-0.4l0.6-1.4l2.3-0.4l-1.2,2.3v1.8l1.6-1.6l3.9,0.1
            l0.4-2l2.5-0.5l2.5,0.5l-1.1-1.5l-1.5-1.1l-0.8-1.4h2.8l2.9-0.9h0.9l-0.3-2.2l-1-0.8v-3l0.6-2l1.9-4.7l2.3-1.6l2-2.2l2.5,1.5
            l1.9-0.3l0.6,2.6l-2,2l-1.6,2.2l4,0.2l0.8-0.8h2.5l1.8,1.8l-1.6,1.6c0,0-0.1,1.6,0.6,1.6c0.8,0,2.8,0.9,2.8,0.9l0.6-2.2l-1.1-1.1
            l2.8-1.5h2.5l2.6-1.8l1.5,1.5l2-1l1.5,1.5l1.3,2.5l1.5,1.3l0.9-1l1.3,1.3l1.7,1.7l-0.3,2.7l-2.5-0.5l1,1l2.5,1.4l1.6,2.7l-1.5,1.5
            v2.5l-1.3,1.8l-1.2,2.5l-1.5,1.5l-2.5,3v1.3l-0.8,3.5l-1.9,1.9l-0.8,3.9l-3,0.8l-1,2.9l-2.1,2.1l-0.5,3.3l-0.6,2.3l2.3-1L452,187
            l2.3-1.4c0,0,2-0.6,3.2-0.6c1.1,0,1.5-2.3,1.5-2.3l2.2-1.8l2-0.8l1.2-2.3l-1-1l-1.5-0.1l0.1-1l-0.1-2l1.9-0.1h1.5l0.3,2.2l1,0.8
            l-0.3,1.2l-0.9,0.9l0.6,1.5l1.1,1.7l1.1,1.1l-0.4,2.5l0.9,1.9l2.6,1v1.3l3.4,2.5l1.6,1.6v3.1l1.8,3.4h2l0.2,2.6h-1l-0.5,1.8
            l-2.4,2.4l-2.1,1.4l0.1,2.6v2l-1.9,0.7l-0.7,2.5l1.4,1.4v2.1l-0.8,1.7l-2.6-0.8l-1.9,0.6l-2.8,3.9l-2.7,1L462.6,227.2"
                        />
                        <path d="M444.1,136.7l-0.5,0.7l1,0.7l0.5-0.8L444.1,136.7" />
                        <path
                            d="M439.2,126.4l-1.2,0.7l1.6,0.8l1.1,0.8l1.3-0.3l-0.3-1.5l-1.3,0.3
            L439.2,126.4"
                        />
                        <path
                            d="M427.9,120.8v-1.6l0.1-2.9l-0.8-1.6l-1.2,0.1l-1.6,2l0.1,1.7
            l-0.7,1.2l0.5,3.1l-1.4,1.4l0.1,3.1l-0.7,2.6l-0.6,2.6l1,0.6l2.2-1.7l1.3-0.8l2.9-1.5l2.3-0.2l2.9-2.2l1.4-2.6l-0.9-2.3l-1-1.7
            l-1.4,0.6l-1.4-2.4l-1-1l-1.3,1.5L427.9,120.8"
                        />
                        <path d="M415.5,143.7l-1.2,1.4l-1.5,0.3l1.4,1l1.4-1.4L415.5,143.7" />
                        <path d="M396.3,143.2l-0.6,0.9l0.8,0.7l1-1.5L396.3,143.2" />
                        <path d="M374.6,164.4l-1.3,1l0.7,1.4l1.1-0.2L374.6,164.4" />
                        <path d="M374.1,179.4l-0.2,1.3h2l1,1l0.1-1.7l-1.2-0.2L374.1,179.4" />
                        <path d="M367.4,111.3l-0.7,0.7l0.8,0.5l1.3,0.9l0.3-1.2L367.4,111.3" />
                        <path
                            d="M360.4,191.4l-0.8,1l0.5,1.4l0.8,1l1.1-1.3l0.5-1.5l-1-1
            L360.4,191.4"
                        />
                        <path d="M377.1,96.4l-1,0.2l-0.6,0.6l1,1l1.3-0.4l0.8-0.8L377.1,96.4" />
                        <path d="M398.9,94.5l-0.9,0.9l0.7,0.7l0.7,0.7l1.5-1.4v-1L398.9,94.5" />
                        <path
                            d="M408,93.2l-0.3,1.9l-1.7,0.8l-2.6,1.7v2.4l1,1l-1.4,1.4l-0.9,1.8
            l1.2,1.7l0.5,1.7l2.2,1.9l2-1.9h1.3l2-1.1l2.4-0.8l-0.7-2.3v-2.9l1.4-1.2l-1.2-1.2l-2.4-2.4l-0.9-1.9L408,93.2"
                        />
                        <path
                            d="M402.4,109l-1.6-0.2l0.3,2.1v1.3l2.1,0.6l0.9,0.9l1.3-1.3l0.7-1.9
            l-1.2-1L402.4,109"
                        />
                        <path d="M377,157.7l-0.6,0.6l0.8,0.7l1.3-0.4L377,157.7" />
                    </g>
                    <path
                        data-title="Томская область"
                        data-code="RU-TOM"
                        d="M367.4,329.8l1.4,3l1,2.2l-2.3,2.3l0.8,2.5l2.8,0.9l3.4-0.9h4.1
        l3,0.8l1.5,2.3l2.4,2.4l0.8,2.9l3.7-0.3l1.3,1.3l-2.2,2.4l-2,2l-1.2,3.2l1.9,3l0.6,2h2.2l3.4,1.1l0.4,2.9l-2.8,1.4l-1.6,2.9v3.8
        l-2.4,1l-2.7,2.7l-1.4-1.4l-2.8,1.5l-2.3-1h-2.7l-4.3,3.8H367l-2.6,1.3l-3.2-0.2l-3.3,3.5l-1.4-1.4l1.2-1.2l-1-1v-2.4l-0.5-3.2
        l-1.6-0.8l-1.8,1.8l-3,0.8h-2.6l-2.2-3h-5.5l-2.5-3.5l-1.7-1.7l-2.1-2.1l-3.4-1.4l-2.9-1.5l-3.4-0.5h-3.1l-1.5-1.5l-2.8-0.7
        l-0.8-2.2l-1.4-2.9l0.4-3.2l-1.5-1.5v-2.6l1.4-3.3l-0.4-1.2l3.6-2.1l0.4-2.7l3.4-2.5l4.2-1.2l-0.2-4l3-6.6l4.1-0.6l3,1.3h4.4
        l1.1,1.6l4.1,0.5l3.2-1.2l2.9,2.9l2.4-0.6l3.4,1.7l0.9-3.1l5-2.1L367.4,329.8"
                    />
                    <path
                        data-title="Кемеровская область"
                        className="rf-empty"
                        data-code="RU-KEM"
                        d="M389.7,378.7l-0.4-2l0.6-2.7l-2.4,1l-2.7,2.7l-1.4-1.4l-2.8,1.5
        l-2.3-1h-2.7l-4.3,3.8H367l-2.6,1.3l0.4,4.2l0.9,1.8l0.7,3.8l-0.5,5.4l1.6,2.4l1.5,2.9h2.3l1.6,3.1l1.4,1.4l0.5,2.4l2,1.2h1.9
        l0.1,1.7l-1.9,0.9l1.1,1.9l0.3,2.5l0.8,2.1l2.5,2.2h2.5l2.2,1.1h1.2l0.1,2.3h1.7v-2.1l1.8-1.8l2.2-2.2L392,418l-0.8-1.6l0.3-1.4l1-1
        l-1.2-1.6l-1.3-1.3l2.3-3.4v-2.7v-1l0.8-0.8v-1.6l-2.2-0.4l-1.5,1.5l-1.8-1.8l2.3-0.6v-2.3l0.6-2l-1.3-2.5l-0.9-2.5l1.9-1.6l2.3-2.3
        l1.5-0.6v-3.3l-1.5-2.9l-1.8-0.6L389.7,378.7"
                    />
                    <path
                        data-title="Республика Хакасия"
                        data-code="RU-KK"
                        d="M390.2,389.3l1.4,2.4l2.4,2.6l2.5,0.5l1.2-1.2l3.7,0.4l2.4-0.8
        l0.8,1.6l-0.8,3.4l2.6,2.6l0.8,3l2.7,2.7v2.4l0.7,2.1l-0.8,2.6l-2.1,2.1l0.3,2.6l-2.1,2.1l-2.3,1l-1.1,2.8l-0.9,1.3l-2.7,1.4v2.8
        l-2.4,2.4l-4.7-0.8l-1.1,1.1h-1.3L388,435h-2l-2.6-2.6l1.2-1.2l-0.6-2.5l3.4-3.4h1.7v-2.1l4-4L392,418l-0.8-1.6l0.3-1.4l1-1
        l-1.2-1.6l-1.3-1.3l2.1-3.1l0.2-4l0.8-0.8v-1.6l-2.2-0.4l-1.5,1.5l-1.8-1.8l2.3-0.9v-2l0.6-2l-1.3-2.5l-0.9-2.5L390.2,389.3"
                    />
                    <path
                        data-title="Республика Адыгея"
                        className="rf-empty"
                        data-code="RU-AD"
                        d="M32.7,366.7l-0.8-1.2l-1.4-1.4l-0.7,0.3v1.2l0.6,1.1l2.7,2.8h1.3
        l0.4-0.5l1.4,0.9l0.7,1.7l-0.8,0.8l-1.4-0.1l-0.8,1.5h-1.2v2.1l0.5,1.2l-0.3,1H30l-1-0.5l-1.1,1.1l1.9,2.4l0.7,1.3l0.6-0.6l1.3-0.2
        v-1l0.9-0.8l1.4-1.4l1.3-1.9l1.1-1.1l1.2-0.5l0.5,1.7l0.7-1.4v-2.8v-1.8l-0.9-0.6l-0.8-0.8l-1.1-0.6l-0.4-1.4l-1.6-0.5l-0.7,0.3
        L32.7,366.7"
                    />
                    <g
                        data-title="Республика Саха (Якутия)"
                        className="rf-empty"
                        data-code="RU-SA"
                    >
                        <path
                            d="M638.5,97.5l-2.4,2.4v1.8l1.2,1.2h-2.5l-2.3,1.8l-3.2,1.4
            l-3.5-3.5l-1.7-1.7l-3.5,0.2l-4.2,0.6l-2.4,2.9l-2.7,2.7l-3.2,4.7v3.7l-2.5,3.2l-2.4,2.4l-2.7,0.1l-2.4-1.3l-3.4,1.9
            c0,0,0.8,3.3,0.4,2.9c-0.4-0.4-2.4-2.5-2.4-2.5l-1.8,2h-2.8c0,0-1.7-0.6-0.9-1.4c0.8-0.8,1.9-2.3,1.9-2.3l-2.3-1.6l-3.8,1.6
            l-2.7,1.3l-1.1,3.2l-0.5,1.8l0.8,4.1v2.4l-2.2,2l0.6-4.9l-2.4-0.8l-1.9,1.9l-2.2,1l0.9-3.4c0,0,2.4-0.9,2.4-1.6
            c0-0.8-0.5-1.3-0.5-1.3l-3.7,1.6l-4.7,1.8l-1,2.9l-3.5,1.8h-2.4l-1.6,1.6l2.7,1.6l0.4,1.8l-2.8,2.5v2.7l1.5,1l1.3-3.3l0.9,0.8v2.8
            l2.2,1.1l1.9,1.9l0.9,0.5l-1.9,1.9l-2.4-0.1l-1.4,1.5l-1.1,1.1l3,1.3v1.9l-2.8-0.8l-2.4,0.1l-3.3,0.8l-1.8,3.3l0.6,3.3h-3.2
            l-2.8,0.8l-1.8-1.8l-3.2-0.4v2.3l1.6,1.6v1.8l0.8,1.9l1.3,4.2l-0.3,2.4l-2.9-1.5l-1.1,1.1l-2.7-0.5l-1.9-1.3l-2.5-2.5l-2.6,0.1v-2
            c0,0-1.1-1.8-1.1-1.1s-1.4,1.3-1.4,1.3l-1.8-1.8l1-1l1.6-0.5v-2.8l-1.3-1.3l-2.4,1.5v-2.4l-1.5-1.5h-1.5l1-3.1l-3-1l-0.9,0.9
            l-2.8-2.2c0,0-0.3,1.2-1.2,1.2s-0.8,2.3-0.8,2.3l-1,0.8l-1-1.1l-1,0.3l-0.5,1.5l-2-1.4l-1.3-1.3l-1.6,1.2l-1.5-1.5l-0.4,1.5
            l-0.5,2.9l1.9,2.9l0.4,2.5c0,0,0.6,2.3,0,2.3s-3.4,0.5-3.4,0.5l-2.7,2.3l-2.7,1l-1.5-2.4l-0.4,1.4l-0.1,1h-3.9l-2.1-2.1l0.9-0.9
            l-1-1.5l-3.8,1.1l-4.8,0.9l-3.2,1.6l-2.3,2.3l-1.2,1.2l1.8,1.6H475l-1.5-2.2v-2.5l-1.3-1.3l-1.6,2.2l-0.5,1.5l-2.5-1l-2.2-0.5
            l0.3,2.2l1,0.8l-0.3,1.2l-0.9,0.9l0.6,1.5l1.1,1.7l1.1,1.1l-0.4,2.5l0.9,1.9l2.6,1v1.3l3.4,2.5l1.6,1.6v3.1l1.8,3.4h2l0.2,2.6h-1
            l-0.5,1.8l-2.4,2.4l-2.1,1.4l0.1,4.6l-1.9,0.7l-0.7,2.5l1.4,1.4v2.1l-0.8,1.7l-2.6-0.8l-1.9,0.6l-2.8,3.9l-2.7,1v1l0.7,1.7l1,2.2
            l2.2,1.5l0.2,4.2l2.2,4v1.7l0.6,3.2l0.7,4.9l-1.9,0.6l-1.3,2.1l-1.2,1.2l0.7,2.1l0.7-0.7l2.4,1v2.1l0.9,0.9l-0.9,0.8v2.2l2.2,1.2
            l0.7,3.5l2.6,2.6l-0.9,2.5l-1.5,2.4l0.6,1.9v1.7l-0.8,0.8v2.5l1.3,0.6h2.1l1-1h1.2l1.1,1.3l2.3-0.9l1.7,0.8l1.4-1.4l1.9,0.1l0.1,1.5
            l1.8,1.8v1.6l-1.7,0.5v2.5l2.1-0.2l2.1-0.6l1.9,1.3l1.6,2.8l0.7,2.8l0.9,0.9l-0.8,0.8v1.8l2,0.9h1.8l1.4,1.4l-0.3,2.3l-1,1v1.7
            l1.4,1.4l0.7,1.7l0.2,1.5l1.6,0.6l2.2,0.9v3.1l-0.5,3.2v3.6l-0.4,3.8l0.3,6.3l3,3l2.7-0.2l1.3-1.9l0.8-2.4h2l4.2-1.6l0.4-2.1
            l1.6-0.7l1.7,1.7v2.2l1.4,2v-2.6l3.3-2.3V323l2.4-2.4l0.9-2.8l-0.7-2.5l1.8-1.8l0.8-2.5l1.5-1.8l2.7,0.4l2.7-0.3l2.8,1.3l2.5,0.3
            v2.3l1.2,1v1.8l2.8,0.4l1.8-1.8v-1.9h2l1.4,1.4l1.7,1.9v1.8l1.6,0.2l1,2.8l1.7,1.7l1.9,1.9l-0.4,1.4l1.3,1.3l0.6,2.9l2.8,1l2,2.6
            l1.9,0.6v2.2l2.1,0.6l2.2-2.2l2.5-2.1h2.5l1.1-1.1l1.4,1.4l1.9,0.4l0.9-0.9h2.5l1,1.3h1.6l0.5-1.9l3.2,0.6l1.1-1.1l2.3-0.5l2.1,1
            l2.7,2.7l2.1-0.9l2.3-0.6l1-1.4l1.7,0.8l1.3-1.3h1.4l1.3,1.3l1.3-1.3l3.7-3.7l2.7,0.4v-1.7l3.4-1.3l2.5,0.5l2.5-1.6l-0.2-2.6
            l4.1-2.5l3.9-3.2l-1.4-3.3v-2.5l-1.7-1.7l1.5-1.5l0.4-3.3l-3.5-1.5h-2.8l-0.3-1.7l2-0.4l-1-2.1l0.6-3.9l-2.4-0.4l-2.4-2.5l2.1-0.5
            l0.2-3l-0.9-0.9l-0.6-1.6l0.8-2l-1.6-1.6l1.3-1.8l-1.1-1.1v-2.7l2.6-1.3l1.7-1l2.1,0.2l1.7-1.7l2.3-0.9l0.3-2.2l-1.5-1.5l0.8-2.1
            l2.9-0.6l3.9-3.9l-0.2-4.4l2.8-0.9v-1.4l-2.7-2.7l-0.6-1.9l-3-3l-1.9-0.4l-0.4-2.5l0.5-2.9l-1.5-1.5l2.3-3v-2.7l0.2-3.3l-1.2-1.2
            l-0.6-2.4l-1.1-1.1l-0.1-2.9l2.2-0.5l1.9,0.5l2.5,1.3l2.5-1.3l2-0.6l2-2l1.4-2.5l1.9,0.1l1.1-0.7l0.8,0.8l1.1-0.4v-2.1l-0.2-3.5
            l0.4-2.4l-1.9-1.9l-0.5-1.5l-1.7-1.7l-3-1.5l-0.8-1.8l-2.1-2.8v-1.5l-1-0.5l0.5-2.5l0.8-0.8l-1.4-1.4l0.4-2.2l3.3-1.3v-1.2l2.5,0.1
            l-0.2-2.8l-1.9-0.6l-1.3-1.7l1.6-1.5h1.6v-2l1.6-1.1v-1.4l2-0.5l1.2,1.4l1.7-0.2l0.9-1.8l-0.6-1.6l1.7-2.8l0.6-1.8l1.7-0.3v-1.6
            l-0.9-2.3l-2.1-1.2l-2-2v-2.3l-0.2-2.5l-1.9-1l-1.3-1.8v-2.1l1.5-0.3l-0.7-2.1l-2.1-0.6l0.4-1.9l2.2-0.9v-2.2l1-1h2.5l0.2-2.6h3.2
            l1.3-1.3l-0.8-2.6l1.1-2.1l-1.5-2.5h-1.4l-1.3-2.7l-1.5-1.5h-2.1l-2.6,0.9l-2.4-1l-2.5-0.4v-2.9l-2-1.1l-1-2.5l1.5-2.4v-2.6l1.7-1.2
            v-2.5l1.2-3l2.6-2l-2-3.3l-2.3-0.9l-0.6-1.6l-2.5-0.3l-3-1L638.5,97.5z"
                        />
                        <path
                            d="M549.2,100.9L549,100l1.7,0.8l0.9-0.9l1.2-1.2l1.8-1.8l1.1,1.3
            h0.6l1.4-1.4l1.3-1.6l2.5-1.1l1.8,1.4l-1,2.9v1.6l-1.2,1.2l-1.9,0.9l-2.1,0.8h-2.1l-1.6,1.6l-2.5-0.9l-1.3-1.8L549.2,100.9"
                        />
                        <path
                            d="M545,103.3h-3.7l-1.2,1.2l-1.9,1.3l-1.4-1.4l-3.3,1l0.2,2.1
            l0.9,0.9l1.3,1.3l0.9,0.9l-1.3,1l-1.7-1.5l-0.9,0.9l-1.3-1.3l-2.9,0.6l-1.3-0.7l-0.6,1.5l0.9,0.9L526,113v2.2l1.4,1.4l-0.3,1.6h1.3
            l1.6,1.6l-0.5,1.8l2.1,1l2.8,0.1l1.2,1.1h2.1l2.4-0.7v-1.8l-1.5-1.4l0.8-0.8l1.2,1.2l2.1-3.9l2-2l0.9,0.9l1.8-1.8l0.6-1.7l-2.1-0.7
            l-1.4,1.4l-1.7-1.3l-2.1-1.3l-2.1-1.5l0.7-1.5l1.1-1.1l0.9,2.9l1.3,1.3l2.2,0.4l1.4-0.6h1.2l1.1-1.1l-0.6-2.4H546l0.5-2.1v-1.5
            L545,103.3"
                        />
                        <path
                            d="M546.9,123.6l-1,0.9l0.8,2.3l1.3,1.3l1.1-2.1v-1.7l-0.8-0.8
            L546.9,123.6"
                        />
                        <path
                            d="M551.6,125.6l-1.3,1.3l0.7,1.8l1.3,1.3l-0.8,1.8l0.7,0.7l1-2.2
            l2.6-0.6l2.3-0.9l3.1-1.8l-1.6-3h-2.2l-3.1-1l-1.4,1.3L551.6,125.6"
                        />
                        <path
                            d="M525.8,120.7l-1-1l-1.8-0.1l1.2,1.2l0.7,2.7h1.5v-1.5L525.8,120.7
            "
                        />
                        <path
                            d="M468.3,165.4l-2.1,2.1v1.4h1.8l0.6,1.4l1.5-0.7l1.7-1.7v-2.8h-2.2
            L468.3,165.4"
                        />
                        <path d="M543.2,84.5l-0.7,1.3l0.7,0.7l0.9-0.7L543.2,84.5" />
                        <path
                            d="M536.8,134.7l-2.2-0.5l-0.7,0.6l1.3,0.6l1.6,1.3l0.7-0.9
            L536.8,134.7"
                        />
                    </g>
                    <path
                        data-title="Ленинградская область"
                        data-code="RU-LEN"
                        d="M103.3,194.1h11.6v3.7l1.4,4.7l4.8,13.8l3,1.4l1.8,3.4l1.4,5.3
        l-1.8,3.9l-1.6,2.1l-1.6,0.2l-0.8,1.4l-2.4-0.4l-1.4,1.4l-1-3.8l-1-1l-1.5-0.5l0.8-1.6v-1.5l-1.6-0.7l-0.2-1.5l-1.8-0.1l-1.1,1.1
        l-1.4-1.7l0.4-3.3l-1.1-0.3l-1.3-1.3l-1.2,1l-1.3,0.3l-2-0.8l-2-0.2h-2.9l-0.9-1.8l-1.1,0.5l-0.9,0.9l-1.7-0.2l-0.5-2.5l0.7-2.3
        l-0.8-1.8l-0.1-1.8l-1.6-1.6l0.8-1.7l-1.5-1.8l2.5-1.5l3.4,0.8l0.5-1.9l1.1-1.1l2.2,1l1.8,1.9l2.8,0.9l1,2.3l-0.4,0.4v0.7l-0.6,2.3
        l0.9-0.2l1.1,0.8l0.5-0.4h0.5l1.1-0.5v-1l0.6-0.7v-1l-0.3-0.4l-0.9,1l-0.3-3.7l-2.3-2v-4.3l2-0.5l0.6-0.6l-2.8-1.1L103.3,194.1"
                    />
                    <path
                        data-title="Республика Карелия"
                        className="rf-empty"
                        data-code="RU-KR"
                        d="M114.9,194.1v3.7l1.4,4.7l4.8,13.8l3,1.4l1.8,3.4l1.4,5.3l2.9-1.6
        l1.9-2.7l1.4,0.8l2.3-1.3l2.1-0.7l2.1,0.4l1.8,0.8l2.1-0.4l1.6,1l0.1,1.1l1.8,2l1-1.1l0.9-0.2l2.5-2.5l0.4-2l-0.3-1.7l2.1-0.6l1-2.6
        l-2.4-2.4l0.3-3.2l0.6-1.4l1.6-0.8l0.8-1.8l-0.1-2l0.8-0.9l1,1.3l0.6-0.6l1.3,0.9l1.6-2.3l1.8-3.2l-1.9-6l2.3-2.3l1-4.5l2.5-2.5
        l3.1-1l1.8-3.9l0.5-2.8l-1.8-1.8l1.4-1.4l-0.1-3.2l-1.6,0.1l-1.3-0.8l-2.1,0.5l-1.4-1.4l0.7-1.9l0.3-2.3l-6.1-5.5l-4.7,8.7l-2.2-1
        l-4.4,3.5l-2,0.1v4.3l-2.3,0.9v3.3l-3,2.4h-3.5l-0.9,8.4v3.3l-5.3,1.9L114.9,194.1"
                    />
                    <path
                        data-title="Санкт-Петербург"
                        data-code="RU-SPE"
                        d="M104.5,207.8c0,0.1,0,0.1-0.1,0.1c-0.1,0.1,0,0.1,0,0.2
        c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.1s0,0.1-0.1,0.1c-0.1,0.1,0,0.2-0.1,0.2c-0.1,0.1,0,0.1-0.1,0.1
        c-0.1,0.1,0,0.1,0,0.2c0,0.1,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.1
        c0,0.1,0,0.1,0,0.2s0,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1
        c0.1,0,0.2-0.1,0.2,0c0.1,0.1,0.1,0,0.1,0.1c0.1,0.1,0,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0.1,0.1
        c0.1,0.1,0.1,0,0.1,0.1c0.1,0.1,0.2-0.1,0.2,0c0.1,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.1,0.1c0.1,0.1,0.1,0.1,0.1,0.1
        c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.2,0c0.1-0.1,0.1-0.1,0.1-0.1s0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.1-0.1
        c0.1-0.1,0.1,0,0.2,0c0.1,0,0.1,0,0.1-0.1c0.1-0.1,0.1,0,0.1-0.1c0.1-0.1,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2
        c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.1-0.1c0-0.1,0.1,0,0.1-0.1s0.1-0.1,0.1-0.1
        c0-0.1,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.3c0-0.1,0-0.1,0.1-0.1c0.1-0.1,0-0.1,0-0.2
        c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.1c0-0.1,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.1-0.1c0-0.1,0-0.1-0.1-0.1
        c-0.1-0.1-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0
        c-0.1,0-0.2-0.1-0.2,0c-0.1,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.2-0.1
        c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2,0c-0.1,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1
        c-0.1,0-0.1,0.1-0.1,0.1h-0.1v0.1h0.1"
                    />
                    <path
                        data-code="RU-HR"
                        data-title="Херсонская область"
                        className="rf-empty"
                        d="M17.3,337.4 17.9,336.4 18.2,335.5 18.6,335 19.3,334.3 20.1,334.2 21.9,334.6 23.2,333.9 26,330.6
        27.4,325 25.9,322.4 25.2,321.4 24.4,319.8 23.8,319.1 23.6,318.3 23,317.5 21.9,316.9 21.8,316 20.5,316.3 19.7,316.9 18.7,318
        17.7,317.8 16.9,318 17,318.9 17.3,319.7 16.4,319.9 16.2,319.1 15.5,319.7 15.1,319.8 14,319.1 13.8,317.9 13.4,318.4 12.7,317.2
        11.7,317 11.2,316.6 10.4,316.8 9.7,317.5 9,316.7 8.2,317.4 8.1,318.3 7.8,319.2 6.9,318.9 6.7,318.1 5.8,318.3 6.2,319.6
        6.7,320.3 6.9,321.1 5.5,321 5.1,318.9 4.8,317.6 5.5,316.6 4.4,316 4.2,316.9 4.2,319.2 4.5,320.4 5.1,321.1 5.9,322.3 6.2,323.6
        7.3,324.6 7.6,325.9 8.2,327.1 8.2,326.1 9.4,326.4 8.4,325.3 7.6,324.1 9.1,324.7 9.8,325.9 10.7,326 11.3,326.8 11.6,327.7
        11.5,329 12.8,329.1 13.3,328.9 14.3,328.4 15,329.1 15.2,329.9 15,330.9 15.1,331.7 15.7,332.5 17,332.2 18.2,333.3 17.2,333
        16.5,333.7 17.5,333.8 17.2,334.8 17.9,334.2 18.7,333.6 18.8,334 18.1,334.7 17.8,335.6 17,336.2 16.8,336.7 16.8,337.1 "
                    />
                    <path
                        data-code="RU-ZP"
                        data-title="Запорожская область"
                        className="rf-empty"
                        d="M31.9,339.7 32.4,339.5 33.3,339.3 33.7,339.2 34.6,339 36,338.9 37.4,338.8 42.1,335.3 41,330.5
        39.8,329.4 38.7,328.7 38,327.9 38.3,326.9 38.5,325.9 38.8,325.4 38.2,324.6 38,324.1 37.9,323.2 37.4,323.4 36.9,323.1
        36.3,322.7 36.2,322.2 35.6,322 35,321.2 33.9,320.4 33.7,319.6 33.3,320.2 33.1,320.7 32.3,321.3 31.9,321.9 31.4,322 31.3,323
        30.1,324.2 29.4,324.9 28.4,324.6 28.1,323.7 28.5,323.2 28,322.9 27.3,322.5 26.4,322.3 25.9,322.4 25.5,322.5 25.2,323.1
        25.4,323.9 25.9,324.3 25.6,324.8 24.8,325.5 24.5,326 24.3,327 24.4,327.4 24.5,328.3 24.4,329.3 23.5,329.5 22.9,329.2 22,329.4
        22.1,329.9 21.9,330.9 21.7,331.9 22.2,332.2 22.4,332.7 22.1,333.6 21.9,334.6 22.4,335 23.3,334.7 22.5,335.4 21.6,335.6
        20.7,335.8 20.2,335.5 19.3,335.7 19.4,336.1 20.4,336.4 21.5,336.6 22.3,336.4 23.1,335.7 24.4,335.4 24.8,334.8 25.8,335.5
        26.8,335.7 28.1,335.5 28.7,335.7 29.8,336.5 29.9,336.9 29.6,337.4 29.1,337.5 28.7,337.6 27.8,337.9 28,338.8 28.9,338.5
        29.8,338.3 30.1,337.8 31,337.5 31.3,337 31.9,337.4 32,337.8 32.1,338.3 32.2,338.7 "
                    />
                    <path
                        data-code="RU-DON"
                        data-title="Донецкая Народная Республика"
                        className="rf-empty"
                        d="M52.5,340.8 51.4,341.3 50.8,341.5 50.6,341.6 50.3,341.7 49.2,341.2 47.9,340.7 47.5,340.9 46.5,341.3
        45.8,341.7 45.1,342 43.7,343.5 42.9,342.2 42.5,342.7 41.2,343 42,342.4 40.7,341.3 39.8,341.1 39.2,340.7 38.3,340.4 37.3,340.3
        36.3,340 35.2,339.3 35.5,338.8 36.4,338.5 36.6,337.6 36.5,337.1 36.8,336.1 37.9,335.4 38.5,335.7 39.1,336.1 40.1,336.3
        40.3,335.3 39.7,335 39.6,334.6 39.9,333.9 39.3,333.2 39.1,332.3 39.3,331.4 39.7,330.8 39.6,330.4 39.9,329.8 40.2,329.2
        40.7,329.1 41.5,328.5 41.7,327.9 42.4,328.7 42.6,329.6 43.4,329 44.7,328.2 44.9,327.2 45.1,326.2 45.9,326 46.2,325 46.1,324.5
        46.8,323.5 47.7,323.2 47.7,323.7 48.4,324.5 49.9,324.6 50.8,324.4 51.7,324.1 52.1,324.1 52.5,324 54,324.1 54.1,323.7
        54.2,323.5 55,324.3 56.6,325.8 57.3,326.5 57.4,332.6 56.1,337.1 55.4,339.7 54.2,340.8 "
                    />
                    <path
                        data-code="RU-LUG"
                        data-title="Луганская Народная Республика"
                        className="rf-empty"
                        d="M53,339.9 52.3,341 52.8,341.3 53.4,342.2 54.9,342.8 55.1,343.7 57.5,346.3 60.3,343.5 60.5,343.3
        60.9,343.1 61.2,343 61.6,342.7 62.2,342.4 61.7,339.6 62,339.2 62.3,338.9 62.7,338.6 63,338.2 63.5,337.8 63.8,337.9 65.3,338.1
        65.2,336.1 65.7,336 66.5,335.9 66.9,335.9 67.3,335.8 67.6,335.8 67.9,335.8 68.2,335.7 68.4,335.7 68.7,335.6 69.9,335.5
        70.1,335 70.2,334.6 70.5,334 70.6,333.7 70.8,333.3 71,333 71.1,332.8 71.3,332.6 71.5,332.4 71.8,332 69.4,331 69.4,329.8
        69.4,328.7 69.4,328.3 68,326.6 67.6,326.1 67.2,325 67.1,324.3 67,324.1 66.9,323.8 66.8,323.6 66.8,323.2 65.5,322.5 64.9,322.1
        64.6,321.8 64.1,321.2 63.7,320.7 63.2,320.2 62.9,319.8 62.4,321.1 61.6,321.7 61.3,322.2 60.4,322.4 59,322.6 58.3,323.7
        57.6,324.7 57.2,325.3 56.4,325.9 57,326.7 56.3,327.8 55.5,328.4 56.1,329.2 55.7,329.7 55.5,330.7 54.6,330.9 54.1,330.9
        53.7,331.5 53.5,332.4 53.6,333.3 53.4,334.3 52.5,334.5 52.5,334.9 52.6,335.4 52.8,336.3 52.3,336.4 53,337.6 52.8,338.6
        52.8,339 "
                    />
                </svg>
                <div className="district-links"></div>
            </div>
        </div>
    );
};

export default MapInteractive;
