import React from "react";

export default function PersonalAgreement() {
    return (
        <div className="persAgr">
            <div className="container">
                <div className="persAgr__wrap">
                    <h5 className="persAgr__wrap__title">
                        Согласие на обработку персональных данных
                    </h5>
                    <div className="persAgr__wrap__text">
                        <p className="par">
                            1. Настоящее пользовательское соглашение (далее -
                            Соглашение) определяет условия и порядок обработки
                            персональных данных пользователей сайта (далее
                            соответственно – Пользователь, Сайт) общества с
                            ограниченной ответственностью «АКДОР» (далее –
                            Оператор, ООО "АКДОР"), расположенного на
                            доменном имени https://akdor77.ru/.
                        </p>
                        <p className="par">
                            2. Термины, применяемые в настоящем Соглашении:
                        </p>
                        <p className="sub">
                            2.1. Оператор – общество с ограниченной
                            ответственностью «АКДОР».
                        </p>
                        <p className="sub">
                            2.2. Пользователь – это любое лицо, осуществившее
                            вход на Сайт и принявшее условия настоящего
                            Соглашения, независимо от факта прохождения процедур
                            регистрации и авторизации.
                        </p>
                        <p className="sub">
                            2.3. Сайт – это совокупность текстов, графических
                            элементов, дизайна, изображений, программного кода,
                            фото- и видеоматериалов и иных результатов
                            интеллектуальной деятельности, содержащихся в сети
                            Интернет под доменным именем https://akdor77.ru/.
                        </p>
                        <p className="par">
                            3. Настоящее Соглашение является публичной офертой.
                        </p>
                        <p className="sub">
                            Нажимая кнопку «Отправить» и другие кнопки для
                            отправки данных форм, Пользователь считается
                            присоединившимся к нему.
                        </p>
                        <p className="par">
                            4. Принимая условия настоящего соглашения,
                            Пользователь:
                        </p>
                        <p className="sub">
                            - подтверждает, что все указанные им данные
                            принадлежат лично ему, обязуется не представляться
                            чужим именем или от чужого имени, не указывать
                            заведомо недостоверную информацию, идентифицирующую
                            третьих лиц или относящуюся к третьим лицам;
                        </p>
                        <p className="sub">
                            - подтверждает и признает, что им внимательно в
                            полном объеме прочитано настоящее соглашение и
                            условия обработки его персональных данных,
                            указываемых им в полях форм, текст соглашения и
                            условия обработки персональных данных ему понятны;
                        </p>
                        <p className="sub">
                            - дает согласие на обработку предоставляемых в
                            составе информации персональных данных в целях,
                            указанных в п. 9 настоящего соглашения;
                        </p>
                        <p className="sub">
                            - дает согласие на передачу персональных данных
                            должностным лицам, учредителям, партнерам и
                            контрагентам Оператора в рамках реализации целей,
                            указанных в п. 9 настоящего соглашения;
                        </p>
                        <p className="sub">
                            - выражает согласие с условиями обработки
                            персональных данных;
                        </p>
                        <p className="sub">
                            - дает согласие на направление уведомлений об
                            изменениях мероприятий, на которые приглашен и (или)
                            зарегистрирован Пользователь, и другой информации;
                        </p>
                        <p className="sub">
                            - дает согласие на направление информации о мерах
                            поддержки и мероприятиях, которые, по мнению
                            Оператора, могут представлять интерес;
                        </p>
                        <p className="sub">
                            - дает согласие на проведение опросов и
                            маркетинговых, статистических и других исследований;
                        </p>
                        <p className="sub">
                            - дает согласие на получение новостной рассылки и
                            иных сведений от имени Оператора или от имени
                            партнеров Оператора.
                        </p>
                        <p className="par">
                            5. Пользователь выражает свое согласие на обработку
                            его персональных данных, а именно совершение любых
                            действий (операций) или совокупности действий
                            (операций), совершаемых с использованием средств
                            автоматизации или без использования таких средств, с
                            персональными данными, включая сбор, запись,
                            систематизацию, накопление, хранение, уточнение
                            (обновление, изменение), извлечение, использование,
                            передачу (распространение, предоставление, доступ),
                            обезличивание, блокирование, удаление, уничтожение
                            персональных данных.
                        </p>
                        <p className="par">
                            6. Давая такое согласие, Пользователь подтверждает,
                            что он действует свободно, своей волей и в своем
                            интересе.
                        </p>
                        <p className="par">
                            7. Под персональными данными подразумевается любая
                            информация личного характера, позволяющая установить
                            личность Пользователя, в частности, следующие
                            персональные данные:
                        </p>
                        <p className="sub">
                            фамилия, имя, отчество, пол, возраст, изображение
                            Пользователя (фотография и видеозапись), паспортные
                            данные, сведения о номере и серии страхового
                            свидетельства государственного пенсионного
                            страхования, сведения об идентификационном номере
                            налогоплательщика, контактный телефон (дом.,
                            мобильный, рабочий), место работы, место учебы,
                            образование, квалификация, профессиональная
                            подготовка и сведения о повышении квалификации,
                            место жительства, семейное положение, наличие детей,
                            родственные связи, факты биографии и предыдущая
                            трудовая деятельность, судимость, служба в армии,
                            работа на выборных должностях, на государственной
                            службе, сведения о доходах и иные сведения, которые
                            могут идентифицировать Субъекта персональных данных.
                        </p>
                        <p className="par">
                            8. Согласие Пользователя на обработку персональных
                            данных является конкретным, информированным и
                            сознательным.
                        </p>
                        <p className="sub">
                            Согласие на обработку персональных данных,
                            разрешенных субъектом персональных данных для
                            распространения, оформляется отдельно от иных
                            согласий субъекта персональных данных на обработку
                            его персональных данных. Оператор обязан обеспечить
                            субъекту персональных данных возможность определить
                            перечень персональных данных по каждой категории
                            персональных данных, указанной в согласии на
                            обработку персональных данных, разрешенных субъектом
                            персональных данных для распространения.
                        </p>
                        <p className="sub">
                            Передача (распространение, предоставление, доступ)
                            персональных данных, разрешенных субъектом
                            персональных данных для распространения, должна быть
                            прекращена в любое время по требованию субъекта
                            персональных данных. Данное требование должно
                            включать в себя фамилию, имя, отчество (при
                            наличии), контактную информацию (номер телефона,
                            адрес электронной почты или почтовый адрес) субъекта
                            персональных данных, а также перечень персональных
                            данных, обработка которых подлежит прекращению.
                            Указанные в данном требовании персональные данные
                            могут обрабатываться только оператором, которому оно
                            направлено.
                        </p>
                        <p className="par">
                            9. Оператор обрабатывает и использует персональные
                            данные Пользователя в целях:
                        </p>
                        <p className="sub">
                            - осуществления своей деятельности в соответствии с
                            уставом ООО "АКДОР";
                        </p>
                        <p className="sub">
                            - идентификации Пользователя, зарегистрированного на
                            Сайте;
                        </p>
                        <p className="sub">
                            - организации взаимодействия Пользователя с
                            Оператором;
                        </p>
                        <p className="sub">
                            - предоставления Пользователю доступа к
                            персонализированным ресурсам Сайта;
                        </p>
                        <p className="sub">
                            - оказания содействия Пользователям в
                            трудоустройстве и заключении договоров;
                        </p>
                        <p className="sub">
                            - установления с Пользователем обратной связи,
                            включая направление уведомлений, запросов,
                            касающихся использования Сайта, оказания услуг,
                            обработки обращений от Пользователя;
                        </p>
                        <p className="sub">
                            - реализации совместных проектов с партнерами
                            Оператора;
                        </p>
                        <p className="sub">
                            - предоставления Пользователю новостной рассылки и
                            иных сведений.
                        </p>
                        <p className="par">
                            10. Пользователь вправе в любой момент изменить
                            (обновить, дополнить) предоставленную им
                            персональную информацию в части Ф. И. О., номера
                            телефона, адреса электронной почты.
                        </p>
                        <p className="par">
                            11. Оператор не проверяет достоверность
                            предоставленной Пользователем персональной
                            информации и не осуществляет контроль её
                            актуальности.
                        </p>
                        <p className="sub">
                            Оператор исходит из того, что Пользователь
                            предоставляет достоверную персональную информацию и
                            поддерживает эту информацию в актуальном состоянии.
                        </p>
                        <p className="sub">
                            Всю ответственность, в том числе за возможные
                            последствия предоставления недостоверной или
                            неактуальной персональной информации несёт
                            Пользователь.
                        </p>
                        <p className="par">
                            12. Оператор имеет право отправлять информационные,
                            в том числе рекламные сообщения, на электронную
                            почту Пользователя.
                        </p>
                        <p className="sub">
                            Пользователь вправе отказаться от рассылок по адресу
                            электронной почты в любое время.
                        </p>
                        <p className="par">
                            13. Персональные данные Пользователя хранятся
                            исключительно на электронных носителях и
                            обрабатываются с использованием автоматизированных
                            систем, за исключением случаев, когда
                            неавтоматизированная обработка персональных данных
                            необходима в связи с исполнением требований
                            законодательства.
                        </p>
                        <p className="par">
                            14. Оператор обязуется принимать все необходимые
                            меры для защиты персональных данных Пользователя,
                            предусмотренные действующим законодательством в
                            области защиты персональных данных.
                        </p>
                        <p className="par">
                            15. Оператор обязуется соблюдать конфиденциальность
                            персональных данных – не раскрывать третьим лицам и
                            не распространять персональные данные без согласия
                            Пользователя, если иное не предусмотрено Федеральным
                            законом от 27 июля 2006 г. № 152-ФЗ «О персональных
                            данных».
                        </p>
                        <p className="par">
                            16. Согласие на обработку персональных данных может
                            быть отозвано Пользователем на Сайте через чат (с
                            указанием Ф. И. О. Пользователя и номера телефона),
                            или посредством составления соответствующего
                            письменного документа, который направляется на адрес
                            Оператора по почте заказным письмом с уведомлением о
                            вручении, либо вручается лично под расписку
                            уполномоченному представителю Оператор.
                        </p>
                        <p className="sub">
                            При обращении субъекта персональных данных к
                            Оператору с требованием о прекращении обработки
                            персональных данных в срок, не превышающий 10
                            рабочих дней с даты получения Оператором
                            соответствующего требования, обработка персональных
                            данных прекращается, за исключением случаев,
                            предусмотренных Законом о персональных данных.
                            Указанный срок может быть продлен, но не более чем
                            на пять рабочих дней. Для этого Оператору необходимо
                            направить субъекту персональных данных
                            мотивированное уведомление с указанием причин
                            продления срока.
                        </p>
                        <p className="par">
                            17. Согласие на обработку персональных данных
                            действует бессрочно.
                        </p>
                        <p className="par">
                            18. Оператор вправе вносить изменения в настоящее
                            соглашение. При внесении изменений в актуальной
                            редакции указывается дата последнего обновления.
                            Новая редакция соглашения вступает в силу с момента
                            её размещения на Сайте.
                        </p>
                        <p className="par">
                            19. Оператор обязуется использовать персональные
                            данные Пользователя, полученные в результате
                            использования Сайта в соответствии с требованиями
                            законодательства о защите персональных данных, в том
                            числе федерального закона № 152-ФЗ от 22.02.2017 «О
                            персональных данных» в редакции, действующей на
                            момент обработки таких персональных данных.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
